import Axios from "axios";
import { API_URL, IUserVault, IVaultKey, IAccessKey, ICollabRole, IVaultCollaborator, IVaultInvitation } from ".";
import { DataModel } from "./common";

interface KeyRequest {
    vault: string;
    code: string;
    description: string;
    format: string;
    data: string;
}

export class VaultClient{

    async listModels(){
        return Axios.get(
            `${API_URL}/data-models`,
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as {[key:string]: DataModel};
            }
            return {};
        })
    }


    async list(){
        return Axios.get(
            `${API_URL}/vaults`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IUserVault[];
            }
            return [];
        })
    }

    async single(id: string){
        return Axios.get(
            `${API_URL}/vaults/${id}`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IUserVault;
            }
            return undefined;
        })
    }

    async create(data: any){
        return Axios.post(
            `${API_URL}/vaults`,
            data,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as string;
            }
            return undefined;
        })
    }

    async updateVault(id: string, data: any){
        return Axios.patch(
            `${API_URL}/vaults/${id}`,
            data,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as string;
            }
            return undefined;
        })
    }

    async deleteEntireVault(vault: string){
        return Axios.delete(
            `${API_URL}/vaults/${vault}`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return true;
            }
            return false;
        })
    }

    async createKey(request: KeyRequest){
        return Axios.post(
            `${API_URL}/vaults/${request.vault}/keys`,
            request,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as string;
            }
            return undefined;
        })
    }

    async deleteKey(vault: string, id: string){
        return Axios.delete(
            `${API_URL}/vaults/${vault}/keys/${id}`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return true;
            }
            return false;
        })
    }

    async fetchKeys(vault: string){
        return Axios.get(
            `${API_URL}/vaults/${vault}/keys`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IVaultKey[];
            }
            return [];
        })
    }

    async fetchSingleKey(keyId: string){
        return Axios.get(
            `${API_URL}/keys/${keyId}`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IVaultKey;
            }
            return undefined;
        })
    }

    async updateSingleKey(keyId: string, entry: any){
        return Axios.patch(
            `${API_URL}/keys/${keyId}`,
            entry,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }                             
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as boolean;
            }
            return undefined;
        })
    }

    async createAccessKey(data: any){
        return Axios.post(
            `${API_URL}/accesskeys`,
            data,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as string;
            }
            return undefined;
        })
    }

    async fetchAccessKeyList(){
        return Axios.get(
            `${API_URL}/accesskeys`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IAccessKey[];
            }
            return undefined;
        })
    }

    async addCollabs(vaultId: string, collabsWithRoles: IVaultCollaborator[]){
        return Axios.post(
            `${API_URL}/invitations`,
            {
                vaultId,
                collaborators: collabsWithRoles
            },
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return true;
            }
            return undefined;
        })
    }

    async fetchPendingInvites(){
        return Axios.get(
            `${API_URL}/invitations`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            if(res.data.success){
                return res.data.data as IVaultInvitation[];
            }
            return [];
        })
    }

    async acceptInvite(id: string){
        return Axios.get(
            `${API_URL}/invitations/${id}?action=accept`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            return res.data.success as boolean;
        })
    }

    async declineInvite(id: string){
        return Axios.get(
            `${API_URL}/invitations/${id}?action=decline`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            return res.data.success as boolean;
        })
    }

    async cancelInvite(id: string){
        return Axios.get(
            `${API_URL}/invitations/${id}?action=cancel`,
            {
                headers :{
                    "Authorization": "auth0 "+ localStorage.getItem("sessiontk")
                }
            }
        )
        .then((res) => {
            return res.data.success as boolean;
        })
    }
}