import React, { useMemo } from "react";
import { observer } from "mobx-react"
import { SEARCH_EXP } from "../core/search";
import { useStoreState } from "../state/storeContext";
import BoxUI from "./BoxUI";

function BoxSearchProcessor(props: {search: string}) {
    let tag = props.search.match(SEARCH_EXP.box)?.groups?.tag
    let appState = useStoreState();

    const box = useMemo(() => {
        return appState.vaultState.list.find(
            (box) => {
                return box._id === tag || box.codeName === tag || box.label.toLowerCase() === tag?.toLowerCase()
            }
        )
    }, [appState, tag]);

    return (<>
        {
            box && 
            <BoxUI box={box}/>
        }
    </>)
}

export default observer(BoxSearchProcessor);