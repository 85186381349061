import React from "react";
import { Avatar, AvatarGroup, Box, Button, ButtonGroup, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { IVaultInvitation } from "../core";
import { composeDateTiming, composeInvitationTitle } from "../core/utils";
import { appState } from "../state/AppState";
import { useStoreState } from "../state/storeContext";
import { VaultClient } from "../core/VaultClient";
import { RoundedButton } from "./RoundedButton";
import { css } from "@emotion/css";
import { grey } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Void } from "./Void";

const ReducedFontButton = withStyles({
    root: {
        borderRadius: '32px',
        fontSize: '12px',
        margin: '4px'
    }
})(Button);

const InvitationAvatar = observer((props: { invitation: IVaultInvitation }) => {
    return <AvatarGroup>
        <Avatar src={props.invitation.guest.picture} />
        <Avatar src={props.invitation.host.picture} />
    </AvatarGroup>
})

const InvitationActions = observer((props: { invitation: IVaultInvitation }) => {
    const [working, setWorking] = React.useState(false);
    const appState = useStoreState();
    const profile = appState.profileState.profile;
    const {t} = useTranslation();

    function cancel() {
        toast.info(t("ongoing"));
        setWorking(true);
        new VaultClient()
            .cancelInvite(props.invitation._id)
            .then((done) => {
                if (done) {
                    toast.success(t("toasts.invites.cancel.success"));
                    return appState.vaultState.fetchInvitations();
                }
                else {
                    throw new Error(t("toasts.invites.cancel.error"));
                }
            })
            .catch((err) => {
                toast.warn(err.message);
            })
            .finally(() => {
                setWorking(false);
            })
    }

    function decline() {
        toast.info(t("ongoing"));

        setWorking(true);
        new VaultClient()
            .declineInvite(props.invitation._id)
            .then((done) => {
                if (done) {
                    toast.success(t("toasts.invites.decline.success"));
                    return appState.vaultState.fetchInvitations();
                }
                else {
                    throw new Error(t("toasts.invites.decline.error"));
                }
            })
            .catch((err) => {
                toast.warn(err.message);
            })
            .finally(() => {
                setWorking(false);
            })
    }

    function accept() {
        toast.info(t("ongoing"));

        setWorking(true);
        new VaultClient()
            .acceptInvite(props.invitation._id)
            .then((done) => {
                if (done) {
                    toast.success(t("toasts.invites.accept.success", {box: props.invitation.vault.label}));
                    return appState.vaultState.fetchInvitations();
                }
                else {
                    throw new Error(t("toasts.invites.accept.warn"));
                }
            })
            .catch((err) => {
                toast.warn(err.message);
            })
            .finally(() => {
                setWorking(false);
            })
    }

    if (profile) {
        if (props.invitation.host.id === profile._id) {
            return <ReducedFontButton size="small" variant="outlined" color="primary" onClick={cancel} disabled={working}>{t("buttons.cancel")}</ReducedFontButton>
        }
        if (props.invitation.guest.id === profile._id) {
            return <Box>
                <ReducedFontButton size="small" variant="text" color="primary" disabled={working} onClick={decline}>{t("buttons.decline")}</ReducedFontButton>
                <ReducedFontButton size="small" variant="contained" color="primary" disabled={working} onClick={accept}>{t("buttons.accept")}</ReducedFontButton>
            </Box>
        }
    }
    return <></>
});

const invitationStyles = {
    wrapper: css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    `,
    header: css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    `,
    headerText: css`
        padding: 0px 8px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    `,
    subtitle: css`
        color: ${grey[500]};
    `,
    actionWrapper: css`
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    `

}
export const InvitationList = observer(() => {
    const {t} = useTranslation();

    return <List disablePadding>
        {
            appState.vaultState.pendingInvitations.map((pending, index) => {
                return <Box className={invitationStyles.wrapper} key={pending._id}>
                    {
                        appState.profileState.profile && <>
                            <Box className={invitationStyles.header}>
                                <InvitationAvatar invitation={pending} />
                                <Box className={invitationStyles.headerText}>
                                    <Typography variant="body1" color="var(--palette-text-primary)">{composeInvitationTitle(pending, appState.profileState.profile)}</Typography>
                                    <Typography variant="body2" color="var(--palette-text-primary)" className={invitationStyles.subtitle}>{composeDateTiming(pending.createdAt, t)}</Typography>
                                </Box>
                            </Box>
                            <Box className={invitationStyles.actionWrapper}>
                                <InvitationActions invitation={pending} />
                            </Box>
                        </>
                    }
                </Box>
            })
        }
        {
            appState.vaultState.pendingInvitations.length === 0 && <Void title={t("noInvite")}/>
        }
    </List>
})