import { Box, TextField } from "@material-ui/core";
import React from "react";
import { appState } from "../state/AppState";

interface KeyInputProps {
    type: string;
    data?: string | any;
    onUpdated(data: any): void;
}

function DataInputComposer(props: KeyInputProps) {
    const model = appState.models[props.type];

    function handlePropertyChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let update = {
            ...props.data,
            [ev.target.name]: ev.target.value
        };
        props.onUpdated(update);
    }

    return <div>
        {
            Object.keys(model.properties).map((k) => {
                return <Box paddingY={1} key={k}>
                    <TextField
                        name={k}
                        fullWidth
                        size="small"
                        type={model.properties[k].type}
                        onChange={handlePropertyChange}
                        value={props.data[k] || model.properties[k].defaultValue}
                        label={model.properties[k].label} />
                </Box>
            })
        }
    </div>
}

export function KeyDataInput(props: KeyInputProps) {
    return <DataInputComposer {...props} />
}

export default KeyDataInput;