import { Box, Container, Typography } from "@material-ui/core";
import { Fade, Slide } from "react-awesome-reveal";
import { ArrowRight } from "phosphor-react";
import { RoundedButton } from "../ui/RoundedButton";


function StartJourney() {
    return <Box component="section" className="section-start-journey">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="1164 3988 276 656" width="276pt" height="656pt" className="figure-triangle">
            <g>
                <path d=" M 1438.698 4644 L 1164 3988 L 1440 4102 L 1438.698 4644 Z " fill="rgb(103,58,183)" fill-opacity="0.2" /><path d=" M 1438.698 4644 L 1164 4232 L 1438.698 4102 L 1438.698 4644 Z " fill="rgb(103,58,183)" />
            </g>
        </svg>
        <Container className="wrapper">
            <Box className="left">
                <Box marginY={2}>
                    <Slide direction="right" triggerOnce>
                        <Typography variant="h4">
                            Essayez DataKit aujourd'hui.
                        </Typography>
                    </Slide>
                </Box>
                <Box marginY={1}>
                    <Fade triggerOnce>
                        <Typography variant="body1">
                            Commençez à tirer profit de DataKit et focalisez-vous sur ce qui est plus important. Votre vie est sur internet, pensez-y.
                        </Typography>
                    </Fade>
                </Box>
                <Box marginY={1}>
                    <RoundedButton variant="contained" color="primary" endIcon={<ArrowRight size={24} fill="white" />}>Commencer</RoundedButton>
                </Box>
            </Box>
        </Container>
    </Box>
}

export default StartJourney;