import { Box, ListItemButton, ListItemText, MenuItem, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { IUserVault } from "../core";
import { VaultClient } from "../core/VaultClient";
import { appState } from "../state/AppState";
import { Header } from "./Header";
import ScopeCollaborators from "./ScopeCollaborators";
import UpdateBox from "./UpdateBox";
import { observer } from "mobx-react";
import RuleMatcher from "../core/RuleMatcher";
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import { Routes } from "../state/routes";
import { useTranslation } from "react-i18next";

function BoxSettings(props: { scope: IUserVault }) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const history = useHistory();
    const {t} = useTranslation();
    const profileState = appState.profileState;
    const collab = useMemo(() => RuleMatcher.fromScope(props.scope, profileState?.profile?._id || ""), [profileState]);

    const onBoxDeleted = useCallback(() => {
        setConfirmDeleteOpen(false);
        if (props.scope && props.scope.codeName !== "default" && props.scope._id !== "global") {
            new VaultClient()
                .deleteEntireVault(props.scope._id)
                .then(async (done) => {
                    if (done) {
                        toast.success(t("toasts.box.deleted.success"));
                        return history.replace(Routes.base);
                    }
                    toast.warn(t("toasts.box.deleted.error"));
                    return Promise.resolve();
                })
        }
    },[history, props.scope, t])

    return <Box width="100%">
        <Box padding={2}>
            <ScopeCollaborators scope={props.scope} />
        </Box>
        <Box padding={2}>
            <Box paddingY={1}>
                <Typography variant="h6" color="var(--palette-text-primary)">Actions</Typography>
            </Box>
            <Paper>
                {
                    RuleMatcher.canUpdateScope(collab) &&
                    <ListItemButton dense onClick={() => history.push(Routes.updateBox(props.scope._id))} divider>
                        <ListItemText primary={t("buttons.updateBox")} secondary={t("updateBoxHint")} secondaryTypographyProps={{fontSize: "12px !important"}} />
                    </ListItemButton>
                }
                {
                    RuleMatcher.canDeleteScope(collab) &&
                    <ListItemButton dense onClick={() => setConfirmDeleteOpen(true)} style={{background: red[600]}}>
                        <ListItemText primary={t("buttons.deleteBox")} secondary={t("deleteBoxHint")} secondaryTypographyProps={{fontSize: "12px !important"}} />
                    </ListItemButton>
                }
            </Paper>
        </Box>
        <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(true)} maxWidth="md">
            <DialogTitle>Supprimer un coffre</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{t("deleteBoxHint1")}</Typography>
                <Typography variant="body1">{t("deleteBoxHint2")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDeleteOpen(false)} variant="text" color="primary">{t("buttons.cancel")}</Button>
                <Button onClick={() => onBoxDeleted()} variant="text" color="error">{t("buttons.deleteNow")}</Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default observer(BoxSettings);