import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./assets/translations/fr.json";
import en from "./assets/translations/en.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: en
            },
            fr: {
                translation: fr
            },
        }
    })

export default i18n;