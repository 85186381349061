import { CircularProgress, Fab } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { appState } from "../state/AppState";
import { Image } from "phosphor-react";

interface Props {
    fabProps?: {
        className?: string;
        style?: React.CSSProperties;
    };
}

function FabPPUpdate(props: Props) {
    const profileState = appState.profileState;
    const uploadField = React.useRef<HTMLInputElement>();
    const [selectedFile, setSelectedFile] = React.useState<File>();

    function onSelectPicture() {
        if (uploadField.current) {
            uploadField.current.click();
        }
    }

    function onFileSelected() {
        if (uploadField.current) {
            let files = uploadField.current.files;
            if (files && files?.length > 0) {
                setSelectedFile(files.item(0) || undefined);
            }
        }
    }

    React.useEffect(() => {
        if (selectedFile) {
            profileState.changeProfilePicture(selectedFile)
                .finally(() => {
                    setSelectedFile(undefined);
                })
        }
    }, [selectedFile]);

    return <>
        <Fab color="primary" onClick={onSelectPicture} {...props.fabProps}>
            {
                profileState.updating && 
                <CircularProgress size={16}/>
            }
            {
                !profileState.updating && 
                <Image size={24} fill="white" />
            }
        </Fab>
        <input onChange={onFileSelected} accept="image/*" ref={(el) => { uploadField.current = el || undefined }} type="file" hidden id="up" />
    </>
}

export default observer(FabPPUpdate);