import React from "react";
import { Box } from "@material-ui/system";
import { Player } from "@lottiefiles/react-lottie-player";
import void_asset from "../assets/void.json";
import { Typography } from "@material-ui/core";

export function Void(props: {title?: string}) {
    return <Box display="flex"flexDirection="column" alignItems="center" justifyContent="center">
        <Player src={void_asset} autoplay loop={true} style={{ width: 200, height: 200 }} />
        {props.title && <Box padding={2}>
            <Typography variant="body2" color="var(--palette-text-disabled)">{props.title}</Typography>
        </Box>}
    </Box>
}