import React from "react";
import logo from "../assets/logo.svg";

interface Props {
    size: number;
}

export function Logo(props: Props){
    return <img src={logo} style={{width: props.size+"px", height: props.size+"px" }} alt="Datakit"/>
}

export default Logo;