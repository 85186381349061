import { List, ListItem, ListItemText } from "@material-ui/core";
import {useTranslation } from "react-i18next";

function SettingsAuthenticationAndSecurity() {
    const {t} = useTranslation();

    return <List>
        <ListItem divider>
            <ListItemText primary={t("settings.updateProfile")} />
        </ListItem>
        <ListItem divider>
            <ListItemText primary={t("settings.changePassword")} />
        </ListItem>
        <ListItem divider>
            <ListItemText primary={t("settings.pairMobile")} />
        </ListItem>
        <ListItem divider>
            <ListItemText primary={t("settings.recentSessions")} />
        </ListItem>
        <ListItem divider>
            <ListItemText primary={t("settings.syncToService", {service: 'Google Drive'})} />
        </ListItem>
        <ListItem>
            <ListItemText primary={t("settings.apiKeys")} />
        </ListItem>
    </List>
}

export default SettingsAuthenticationAndSecurity;