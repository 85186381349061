import { makeAutoObservable } from "mobx";
import { INotification, IRawSignal, IUser } from "../core";

class SignalState {
    signals: INotification[];

    constructor() {
        makeAutoObservable(this)
        this.signals = [];
    }

    clone() {
        let clone = new SignalState();
        clone.signals = this.signals;

        return clone;
    }

    signalExists(sid: string) {
        return this.signals.find((s) => s._id == sid);
    }

    appendSingleSignal(s: INotification) {
        let index = this.signals.findIndex((signal) => signal._id == s._id);
        if (index >= 0) {
            this.signals[index] = s;
        }
        else{
            this.signals.push(s);
        }
    }

    appendSignal(...signals: INotification[]) {
        signals.forEach(element => {
            this.appendSingleSignal(element);
        });
    }
}

export default SignalState;