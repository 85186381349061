import { Box, Button, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import { DotLoader } from "react-spinners";
import { IUserVault } from "../core";
import { VaultClient } from "../core/VaultClient";
import { appState } from "../state/AppState";
import { Header } from "./Header";
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next";
import { Routes } from "../state/routes";

function UpdateBox(props: RouteComponentProps) {
    const [working, setworking] = useState(false);
    const params = useParams();
    const [scope, setScope] = React.useState<IUserVault>();
    const {t} = useTranslation();
    
    React.useEffect(() => {
        let id = (params as any).id;
        let found = appState.vaultState.list.find((box) => box._id === id);

        if (found) {
            setScope(found);
        }
    }, [params]);

    const onSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        let form = ev.currentTarget;

        if (scope) {
            scope.label = form.label.value;
            scope.codeName = form.code.value;
            scope.description = form.description.value;

            let client = new VaultClient();
            setworking(true);
            client.updateVault(scope._id, scope)
                .then((id) => {
                    setworking(false);
                    if (id) {
                        toast.success(t("toasts.box.update.success"));
                        props.history.replace(Routes.box(id));
                    }
                    else {
                        toast.warn(t("toasts.box.update.error"));
                    }
                })
        }
    }, [props.history, scope, t])

    if (!scope) {
        return <></>
    }

    return <Box component="form" onSubmit={onSubmit}>
        <Header showReturn>
            <Typography variant="h5" color="var(--palette-text-primary)">{scope?.label}</Typography>
        </Header>
        <Box padding={2}>
            <Box padding={1}>
                <TextField
                    name="label"
                    helperText={t("label.helper")}
                    variant="outlined"
                    fullWidth
                    label={t("label.label")}
                    size="small"
                    required
                    defaultValue={scope?.label.toString()} />
            </Box>
            <Box padding={1}>
                <TextField
                    name="description"
                    helperText={t("description.helper")}
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    label={t("description.label")}
                    size="small"
                    defaultValue={`${scope?.description}`} />
            </Box>
            <Box padding={1}>
                <TextField
                    name="code"
                    variant="outlined"
                    fullWidth
                    label={t("code.label")}
                    size="small"
                    defaultValue={`${scope?.codeName}`} />
            </Box>
            <Box padding={1} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                <Button size="small" type="submit" endIcon={working ? <DotLoader size={24} color="var(--palette-action-active)" /> : undefined} variant="contained" color="primary">{t("buttons.updateBox")}</Button>
            </Box>
        </Box>
    </Box>
}

export default withRouter(observer(UpdateBox));