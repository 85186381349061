import { Box, Container, Typography } from "@material-ui/core";
import { Fade, Slide } from "react-awesome-reveal";
import BoxObjectsPng from "../assets/box_objects.png";

function SectionAbout() {
    return <Box component="section" className="section-about">
        <Box className="left">
            <svg xmlns="http://www.w3.org/2000/svg" style={{ "isolation": "isolate" }} viewBox="0 1103.233 550 419.767" width="550pt" height="419.767pt" className="figure-rectangle">
                <g>
                    <rect x="0" y="1145.233" width="376" height="377.767" transform="matrix(1,0,0,1,0,0)" fill="rgb(0,150,136)" />
                    <rect x="174" y="1103.233" width="376" height="377.767" transform="matrix(1,0,0,1,0,0)" fill="rgb(0,150,136)" fill-opacity="0.2" />
                </g>
            </svg>
            <img src={BoxObjectsPng} alt="box objects" className="img-box-objects" />
        </Box>
        <Box className="right">
            <Container>
                <Box marginY={4}>
                    <Slide triggerOnce direction="right">
                        <Typography variant="h4">
                            Conservez toutes vos informations confidentielles en sécurité.
                        </Typography>
                    </Slide>
                </Box>
                <Box marginY={2}>
                    <Fade triggerOnce>
                    <Typography variant="body1">
                        DataKit est un moyen simple et facile qui vous aide à conserver vos informations confidentielles et vos clés secrètes.
                        Il fournit des coffres, dans lesquels vous pouvez organiser vos objets, ayant un lien commun.
                        Pour chaque coffre, vous pourrez definir un nom significatif, pour rapidement vous en souvenir.
                    </Typography>
                    </Fade>
                </Box>
            </Container>
        </Box>
    </Box>
}

export default SectionAbout;