import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { appState } from "../state/AppState";
import FabPPUpdate from "./FabPPUpdate";
import UserAvatar from "./UserAvatar";

const styled = makeStyles((t) => {
    return {
        root: {
            padding: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        rootMd: {
            minHeight: 180,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        avatarWrapper: {
            height: '256px',
            width: '256px',
            position: 'relative',
        },
        avatar: {
            height: '100%',
            width: '100%',
        },
        floating: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(-50%, 50%)'
        }
    }
}
);

function SettingProfileCard() {
    const profile = appState.profileState.profile;

    const theme = useTheme();
    const styles = styled();

    const matchMdDown = useMediaQuery(theme.breakpoints.down('sm'));

    return <Box borderRadius={4} padding={matchMdDown ? 2 : 4} position="relative">
        <Box className={`${styles.root} ${matchMdDown ? styles.rootMd : ""}`}>
            <UserAvatar sizeInPx={matchMdDown ? 96 : 120}/>
            <Box marginLeft={matchMdDown ? 0 : 4}>
                <Typography variant="h6">{appState.profileState.fullName}</Typography>
                <Typography variant="body1">{appState.profileState.profile?.email}</Typography>
            </Box>
            <FabPPUpdate fabProps={{className: styles.floating}}/>
        </Box>
    </Box>
}

export default observer(SettingProfileCard);