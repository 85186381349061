import { appState } from "../state/AppState";
import styles from "../styles/CreateObject.module.scss";
import { DataModel } from "../core/common";
import React, { useCallback, useMemo, useState } from "react";
import KeyDataInput from "./KeyDataInput";
import { Header } from "./Header";
import { useHistory, useLocation } from "react-router";
import { IUserVault } from "../core";
import { useEffect } from "react";
import { VaultClient } from "../core/VaultClient";
import Loader from "react-spinners/DotLoader";
import { Box, Button, Chip, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next";
import { Routes } from "../state/routes";
import { AesEncryptionScheme, ObjectEncryptionScheme } from "./ObjectEncryption";
import { Encryption } from "../core/Encryption";
import { FileObjectInput } from "./FileObjectInput";
import { Fade } from "react-awesome-reveal";

const WhiteLabelChip = withStyles({
    label: {
        color: 'white'
    }
})(Chip);

function RawCreateObject() {
    const [loading, setLoading] = useState(false);
    const [selectedBox, setSelectedBox] = useState<IUserVault>();
    const [selectedModel, setSelectedModel] = useState<DataModel>();
    const [data, setData] = useState<any>({});
    const [encryptionScheme, setEncryptionScheme] = useState<AesEncryptionScheme>();
    const [uploadedData, setUploadedData] = useState<any>(undefined);

    const location = useLocation()
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (location.search) {
            let search = new URLSearchParams(location.search);
            setSelectedBox(appState.vaultState.list.find((b) => b._id === search.get("box")));
        }
    }, [location.search]);

    const isSubmissionDisabled = useMemo(() => {
        let is: any = Boolean(!selectedModel) || Boolean(!data) || Boolean(!selectedBox);
        is = Boolean(is) || (selectedModel && selectedModel.type === "File" && !uploadedData);
        return is;
    }, [data, selectedBox, selectedModel, uploadedData]);

    const onSubmit = useCallback(async (ev: React.ChangeEvent<HTMLFormElement>) => {
        ev.preventDefault();
        let target = ev.target;
        if (data && selectedBox && selectedModel && encryptionScheme) {
            let keyData = {
                vault: selectedBox._id,
                code: target.code.value,
                description: target.description.value,
                format: selectedModel?.type,
                data: uploadedData ? JSON.stringify(uploadedData) : JSON.stringify(data)
            }

            let encrypted = await new Encryption().encryptObject(keyData as any, encryptionScheme)

            setLoading(true);
            new VaultClient()
                .createKey(encrypted as any)
                .then((done) => {
                    setLoading(false);
                    if (done) {
                        toast.success(t("toasts.object.create.success"))
                        history.replace(Routes.box(selectedBox._id))
                    }
                    else {
                        toast.warn(t("toasts.object.create.error"))
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.warn(t("toasts.object.create.error"))
                })
        }
    }, [data, encryptionScheme, history, selectedBox, selectedModel, t, uploadedData])

    return <Box>
        <Header showReturn={true}>
            <Typography variant="h6" color="var(--palette-text-primary)"> {t("headers.object.create", { label: selectedBox?.label })}</Typography>
        </Header>
        <Box component="form" onSubmit={onSubmit} className={styles.content}>
            <Box paddingY={1}>
                <ObjectEncryptionScheme scheme={encryptionScheme} onChange={setEncryptionScheme} />
            </Box>
            <Box paddingY={1}>
                <TextField
                    label={t("objectTitle.label")}
                    type="text"
                    fullWidth
                    name="code"
                    size="small" />
            </Box>
            <Box paddingY={1}>
                <TextField
                    label={t("objectComment.label")}
                    type="text"
                    fullWidth
                    name="description"
                    size="small" />
            </Box>
            <Box paddingY={1} className={styles.choices}>
                {
                    Object.keys(appState.models).sort((k1, k2) => appState.models[k1].label.localeCompare(appState.models[k2].label)).map((model) => {
                        let md = appState.models[model];
                        return <WhiteLabelChip variant={selectedModel?.type === model ? "filled" : "outlined"} color="primary" style={{ margin: '2px' }} label={md.label} onClick={() => setSelectedModel(md)} key={model} />
                    })
                }
            </Box>
            {
                selectedModel && selectedModel.type !== "File" && <Fade>
                    <KeyDataInput type={selectedModel?.type} onUpdated={(d) => setData(d)} data={data} />
                </Fade>
            }
            {
                selectedModel && selectedModel.type === "File" && encryptionScheme && <Fade>
                    <Box paddingY={2}>
                        <FileObjectInput scheme={encryptionScheme} onUploaded={setUploadedData} />
                    </Box>
                </Fade>
            }
            <Box paddingY={1} className={styles.actions}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-disabled={isSubmissionDisabled}
                    disabled={isSubmissionDisabled}
                    className={styles.action_submit}>
                    {
                        loading && <Loader size={24} color="white" />
                    }
                    {t("buttons.saveObject")}
                </Button>
            </Box>
        </Box>
    </Box>
}

export const CreateObject = observer(RawCreateObject);