import { Button, useTheme, List, ListSubheader, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import { appState } from "../state/AppState";
import { Routes } from "../state/routes";
import { usePrivateBoxes, useSharedBoxes } from "../state/state-hooks";
import { CirclesThreePlus, Lock, ShareNetwork } from "phosphor-react";
import styles from "../styles/Home.module.scss";
import { padZero } from "../core/utils";
import { useTranslation } from "react-i18next";

export const HomeSide = observer(() => {
  const router = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const sharedBoxes = useSharedBoxes()
  const privateBoxes = usePrivateBoxes()

  return <Box className={styles.side_content}>
    <List disablePadding>
      <ListItem>
        <ListItemIcon>
          <CirclesThreePlus size={24} color={theme.palette.primary.light} />
        </ListItemIcon>
        <ListItemText
          primary={t("models.used")} 
          primaryTypographyProps={{
            fontWeight: 500,
            color: 'var(--palette-text-secondary)'
          }}/>
      </ListItem>
      {
        Object.keys(appState.models).map((model) => {
          let objs = appState.vaultState.objects.filter((obj) => obj.format === model);
          if (objs.length === 0) {
            return <React.Fragment key={model}></React.Fragment>
          }
          return <ListItemButton dense key={model} onClick={(ev) => router.push(Routes.model(model))}>
            <ListItemText
              primary={`${appState.models[model].label} (${padZero(objs.length)})`}
              primaryTypographyProps={{
                fontWeight: 600,
                color: 'var(--palette-text-secondary)'
              }}/>
          </ListItemButton>
        })
      }
      {
        sharedBoxes.length > 0 && <>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <ShareNetwork size={24} color={theme.palette.primary.light} />
            </ListItemIcon>
            <ListItemText
              primary={t("boxes.shared")}
              primaryTypographyProps={{
                fontWeight: 500,
                color: 'var(--palette-text-secondary)'
              }} />
          </ListItem>
          {
            appState.vaultState.list.filter((v) => {
              return (v.collaborators || []).length > 0
            })
              .map((v) => {
                let objs = appState.vaultState.objects.filter((obj) => obj.vault === v._id);
                return <ListItemButton dense key={v._id} onClick={(ev) => router.push(Routes.box(v._id))}>
                  <ListItemText
                    primary={`${v.label} (${padZero(objs.length)})`}
                    primaryTypographyProps={{
                      fontWeight: 600,
                      color: 'var(--palette-text-secondary)'
                    }} />
                </ListItemButton>
              })
          }
        </>
      }
      {
        privateBoxes.length > 0 && <>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Lock size={24} color={theme.palette.primary.main} />
            </ListItemIcon>
            <ListItemText
              primary={t("boxes.private")}
              primaryTypographyProps={{
                fontWeight: 500,
                color: 'var(--palette-text-secondary)'
              }}/>
          </ListItem>
          {
            appState.vaultState.list.filter((v) => {
              return (v.collaborators || []).length === 0
            })
              .map((v) => {
                let objs = appState.vaultState.objects.filter((obj) => obj.vault === v._id);
                return <ListItemButton dense key={v._id} onClick={(ev) => router.push(Routes.box(v._id))}>
                  <ListItemText
                    primary={`${v.label} (${padZero(objs.length)})`}
                    primaryTypographyProps={{
                      fontWeight: 600,
                      color: 'var(--palette-text-secondary)'
                    }} />
                </ListItemButton>
              })
          }
        </>
      }
    </List>
    <Box padding={1}>
      <Button fullWidth size="small" onClick={() => router.push(Routes.createBox())} variant="contained" color="primary">{t("buttons.newBox")}</Button>
    </Box>
  </Box>
})