import { observer } from "mobx-react";
import { Route, Switch, useHistory } from 'react-router';
import { appState } from '../state/AppState';
import styles from '../styles/Home.module.scss'
import { List, Bell, User, UserPlus, Users } from "phosphor-react";
import { SearchProcessor } from './SearchProcessor';
import { CreateObject } from './CreateObject';
import ModelUI from './ModelUI';
import BoxUI from './BoxUI';
import { Badge, Box, Button, Container, Dialog, DialogActions, DialogContent, Drawer, Hidden, IconButton, MenuItem, Popover, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import UpdateObject from './UpdateObject';
import CreateBox from './CreateBox';
import UpdateBox from './UpdateBox';
import { FeedsProvider } from './FeedsContext';
import NotificationWatcher from './NotificationWatcher';
import { SearchBar } from "./SearchBar";
import { useState } from "react";
import { RoundedButton } from "./RoundedButton";
import SettingsPage from "./SettingsPage";
import InvitationPage from "./InvitationPage";
import { css } from "@emotion/css";
import { useAuth0 } from "@auth0/auth0-react";
import { useReceivedInvites } from "../state/state-hooks";
import { Routes } from "../state/routes";
import Logo from "./Logo";
import { HomeSide } from "./HomeSide";
import { makeStyles } from "@material-ui/styles";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

const inlineStyles = makeStyles({
  profileButton: {
    textTransform: 'none',
    borderColor: 'var(--palette-action-active)',
    color: 'var(--palette-text-primary)'
  }
});


function Home() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [profileAnchor, setProfileAnchor] = useState<HTMLButtonElement>();
  const [logoutRequested, setLogoutRequested] = useState(false);

  const router = useHistory();
  const theme = useTheme();
  const inlinedStyles = inlineStyles();
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const matchDownFromMd = useMediaQuery(theme.breakpoints.down("md"));
  const myPendingInvites = useReceivedInvites();

  return (
    <FeedsProvider token={localStorage.getItem("sessiontk") || "guest"}>
      <NotificationWatcher>
        <main className={styles.main}>
          <Box className={styles.main_header}>
            <div className={styles.logo_wrapper}>
              <Hidden mdUp>
                <Box paddingRight={1}>
                  <IconButton onClick={() => setSideMenuOpen(true)}>
                    <List size={24} fill={theme.palette.action.active} />
                  </IconButton>
                </Box>
              </Hidden>
              <Logo size={28} />
              <Hidden smDown>
                <Box paddingX={1}>
                  <Typography variant="h6" color={theme.palette.text.primary}>DataKit</Typography>
                </Box>
              </Hidden>
            </div>
            <Hidden mdDown>
              <Box className={styles.search_wrapper}>
                <SearchBar
                  placeholder={t("search")}
                  onCleared={() => appState.setSearch("")}
                  onSearch={(tag) => appState.setSearch(tag)} />
              </Box>
            </Hidden>
            <div className={styles.extra_wrapper}>
              <Box paddingX={1}>
                <Tooltip
                  title={t("notifications") || ""}>
                  <Badge badgeContent={myPendingInvites.length} color="primary">
                    <IconButton>
                      <Bell size={24} fill={theme.palette.action.active} />
                    </IconButton>
                  </Badge>
                </Tooltip>

              </Box>
              <Box paddingX={1}>
                <Tooltip
                  title={t("invitations") || ""}>
                  <Badge badgeContent={myPendingInvites.length} color="primary">
                    <IconButton onClick={() => router.push(Routes.invitations())}>
                      <Users size={24} fill={theme.palette.action.active} />
                    </IconButton>
                  </Badge>
                </Tooltip>
              </Box>
              <RoundedButton
                color="inherit"
                variant="outlined"
                size="small"
                className={inlinedStyles.profileButton}
                endIcon={
                  <>
                    {appState.profileState.profile?.pictureUrl && <img alt={appState.profileState.profile.name.first} src={appState.profileState.profile.pictureUrl} className={css`width:28px;height:28px;object-fit:cover;border-radius:24px;`} />}{
                      !appState.profileState.profile?.pictureUrl &&
                      <User size={24} fill={theme.palette.action.active} />
                    }
                  </>
                }
                onClick={(ev) => setProfileAnchor(ev.currentTarget)}>
                {appState.profileState.profile?.name.first}
              </RoundedButton>
              <Popover anchorEl={profileAnchor} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} onClose={() => setProfileAnchor(undefined)} open={Boolean(profileAnchor)}>
                <Box width={240}>
                  <MenuItem divider onClick={() => router.push(Routes.settings())}>{t("settings.title")}</MenuItem>
                  <MenuItem divider onClick={() => router.push(Routes.invitations())}>{t("invitations")} ({appState.vaultState.pendingInvitations.length})</MenuItem>
                  <MenuItem onClick={() => setLogoutRequested(true)}>{t("logout.title")}</MenuItem>
                </Box>
              </Popover>
            </div>
          </Box>
          <div className={styles.content_wrapper} data-monocolumn={matchDownFromMd}>
            <Hidden mdDown>
              <div className={styles.main_side_left}>
                <HomeSide />
              </div>
            </Hidden>
            <div className={styles.main_side_content}>
              <Hidden mdUp>
                <Box width="100%" padding={2}>
                  <SearchBar
                    placeholder={t("search")}
                    onCleared={() => appState.setSearch("")}
                    onSearch={(tag) => appState.setSearch(tag)} />
                </Box>
              </Hidden>
              <Switch>
                <Route path={Routes.createBox()}>
                  <Fade>
                    <Container>
                      <CreateBox />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.createObject()}>
                  <Fade>
                    <Container>
                      <CreateObject />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.updateBox(":id")}>
                  <Fade>
                    <Container>
                      <UpdateBox />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.updateObject(":id")}>
                  <Fade>
                    <Container>
                      <UpdateObject />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.settings()}>
                  <Fade>
                    <Container>
                      <SettingsPage />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.invitations()}>
                  <Fade>
                    <Container>
                      <InvitationPage />
                    </Container>
                  </Fade>
                </Route>
                <Route path={Routes.model(":type")} render={(route) => {
                  let type = route.match.params.type as string;
                  let model = appState.models[type];
                  return <Fade>
                    <Container>
                      <ModelUI model={model} />
                    </Container>
                  </Fade>
                }} />
                <Route path={Routes.box(":id")} render={(route) => {
                  let type = route.match.params.id;
                  let box = appState.vaultState.list.find((b) => b._id === type);
                  return <Fade>
                    <>{
                      box && <Container>
                        <BoxUI box={box} />
                      </Container>}
                    </>
                  </Fade>
                }} />
                <Route path={Routes.base}>
                  <SearchProcessor search={appState.search} />
                </Route>
              </Switch>
            </div>
          </div>
          <Hidden mdUp>
            <Drawer anchor="left" open={sideMenuOpen} onClose={() => setSideMenuOpen(false)}>
              <HomeSide />
            </Drawer>
          </Hidden>
          <Dialog maxWidth="sm" fullWidth open={logoutRequested} onClose={() => setLogoutRequested(false)}>
            <DialogContent>
              <Typography variant="body1">{t("logout.text")}</Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" size="small" onClick={() => setLogoutRequested(false)}>{t("buttons.cancel")}</Button>
              <Button variant="contained" color="primary" size="small" onClick={() => logout({ returnTo: window.location.origin })}>{t("buttons.exit")}</Button>
            </DialogActions>
          </Dialog>
        </main>
      </NotificationWatcher>
    </FeedsProvider >
  )
}

export default observer(Home);