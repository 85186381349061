import { Box, Button, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { DotLoader } from "react-spinners";
import { VaultClient } from "../core/VaultClient";
import { Header } from "./Header";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Routes } from "../state/routes";


function CreateVault(props: RouteComponentProps) {
    const [working, setworking] = React.useState(false);
    const { t } = useTranslation();

    function onSubmit(ev: React.FormEvent<HTMLFormElement>) {
        ev.preventDefault();
        let form = ev.currentTarget;

        let data = {
            label: form.label.value,
            code: form.label.value,
            description: form.description.value
        }

        let client = new VaultClient();
        setworking(true);
        client.create(data)
            .then((id) => {
                setworking(false);
                if (id) {
                    toast.success(t("toasts.box.create.success"));
                    props.history.replace(Routes.box(id));
                }
                else {
                    toast.warn(t("toasts.box.create.error"));
                }
            })
    }

    return <Box component="form" onSubmit={onSubmit}>
        <Header showReturn>
            <Typography variant="h5" color="var(--palette-text-primary)">{t("headers.box.create")}</Typography>
        </Header>
        <Box padding={2}>
            <Box paddingY={1}>
                <TextField
                    name="label"
                    size="small"
                    helperText={t("label.helper")}
                    variant="outlined"
                    fullWidth
                    required
                    label={t("label.label")} />
            </Box>
            <Box paddingY={1}>
                <TextField
                    name="description"
                    size="small"
                    helperText={t("description.helper")}
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    label={t("description.label")} />
            </Box>
            <Box paddingY={1}>
                <TextField 
                    name="code" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    label={t("code.label")} />
            </Box>
            <Box paddingY={2} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Button type="submit" size="small" endIcon={working ? <DotLoader size={24} color="white" /> : undefined} variant="contained" color="primary">{t("buttons.createBox")}</Button>
            </Box>
        </Box>
    </Box>
}

export default withRouter(observer(CreateVault));