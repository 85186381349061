import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { appState } from './state/AppState';
import { StoreContext } from './state/storeContext';
import Home from './ui/Home';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue } from '@material-ui/core/colors';
import ProtectedByAuth from './ui/ProtectedByAuth';
import Landing from './Landing';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AWS_IDENTITY_POOL_ID, AWS_REGION } from './core/config';
import { Routes } from './state/routes';
import { MuiToCss } from './ui/MuiToCss';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

const AppTheme = createTheme({
  palette: {
    mode: "dark",
    primary: blue,
    secondary: blue,
    background: {
      default: '#202225',
      paper: '#293241'
    },
    text: {
      primary: "#fafafa",
      secondary: "#bababa"
    },
    action: {
      active: "#f9f9f9",
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans',
  }
});

function App() {
  return (
    <Auth0Provider domain={AUTH0_DOMAIN} clientId={AUTH0_CLIENT_ID} redirectUri={window.location.origin + Routes.base}>
      <div>
        <ThemeProvider theme={AppTheme} >
          <StoreContext.Provider value={appState}>
            <MuiToCss rootSelector=":root">
              <div>
                <BrowserRouter basename="/">
                  <Switch>
                    <Route path={Routes.base}>
                      <ProtectedByAuth>
                        <Home />
                      </ProtectedByAuth>
                    </Route>
                    <Route path="/">
                      <Landing />
                    </Route>
                  </Switch>
                </BrowserRouter>
              </div>
            </MuiToCss>
          </StoreContext.Provider>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            pauseOnFocusLoss
            pauseOnHover
            theme="dark" />
        </ThemeProvider>
      </div>
    </Auth0Provider>
  );
}

export default App;
