import { useTheme } from "@material-ui/core";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";

function flattenTheme(theme: any, prefix = "--", previous = "", result: any) {
    Object.keys(theme).forEach((keyProp) => {
        let path = `${previous}${prefix}${keyProp}`;
        let value = theme[keyProp]
        if (typeof value === "object" && value.length) { return; /* skip arrays */ }
        if (path.match(/[@,(,),\s:]/)) {
            return; /* skip any path containing @ ( ) : or spaces */
        }
        else if (["string", "number"].includes(typeof value)) {
            let treatedPath = path
                .toLowerCase()
                .trim()

            result[treatedPath] = value
        }
        else if (typeof value === "object") {
            flattenTheme(value, "-", path, result)
        }
    })
}

export function MuiToCss(props: PropsWithChildren<{ rootSelector: string }>) {
    const theme = useTheme();
    const styleNode = useRef<HTMLStyleElement | null>()

    const flattenedTheme = useMemo(() => {
        let result: any = {}
        flattenTheme(theme, "--", "", result);
        return result;
    }, [theme]);

    useEffect(() => {
        let composed = `
            ${props.rootSelector} {
                ${ Object.keys(flattenedTheme).map(key => {
                    return `${key}: ${flattenedTheme[key]};`
                    }).join("\n") }
            }
        `
        if (styleNode.current) {
            styleNode.current.innerHTML = composed;
        }
    })
    return <>
        <style ref={(el) => styleNode.current = el}></style>
        {
            props.children
        }
    </>
}