import React, { useMemo } from "react";
import { observer } from "mobx-react"
import { SEARCH_EXP } from "../core/search";
import { useStoreState } from "../state/storeContext";
import ModelUI from "./ModelUI";

function ModelSearchProcessor(props: {search: string}) {
    let tag = props.search.match(SEARCH_EXP.model)?.groups?.tag
    let appState = useStoreState();

    const model = useMemo(() => {
        if(tag) {
            return appState.models[tag];
        }
        return undefined;
    }, [appState, tag]);

    return (<>
        {
            model && 
            <ModelUI model={model}/>
        }
    </>)
}

export default observer(ModelSearchProcessor);