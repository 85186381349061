import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { Clipboard, Trash, Check, Pen, Eye, EyeClosed } from "phosphor-react";
import { IVaultKey } from "../core";
import { DataProperty } from "../core/common";
import { appState } from "../state/AppState";
import style from "../styles/components.module.scss";
import { useHistory, useRouteMatch } from "react-router";
import { observer } from "mobx-react";
import { Header } from "./Header";
import RuleMatcher from "../core/RuleMatcher";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Routes } from "../state/routes";
import { Fade } from "react-awesome-reveal";
import { useDecryptedObject } from "../state/state-hooks";
import { FileObjectView } from "./FileObjectView";

export function SingleObject(props: { object: IVaultKey, onDeleted(): void }) {
    const decrypted = useDecryptedObject(props.object);
    const [confirmDeleteAnchor, setConfirmDeleteAnchor] = useState<HTMLButtonElement>();
    const { t } = useTranslation();
    const history = useHistory();
    let model = appState.models[props.object.format];

    const scope = useMemo(() => {
        return appState.vaultState.list.find((v) => v._id === props.object.vault);
    }, [props.object.vault]);

    const collab = useMemo(() => {
        if (!scope) {
            return undefined;
        }
        return RuleMatcher.fromScope(scope, appState.profileState?.profile?._id || "");
    }, [scope]);

    const onDelete = useCallback(() => {
        setConfirmDeleteAnchor(undefined);
        toast.info(t("toasts.object.delete.ongoing"));
        appState.vaultState.deleteObject(props.object._id)
            .then((done) => {
                if (done) {
                    toast.success(t("toasts.object.delete.success"))
                    props.onDeleted();
                }
                else {
                    toast.warn(t("toasts.object.delete.error"))
                }
            })
    }, [props, t])

    return <div className={style.single_object}>
        <Header showReturn>
            <Box>
                <Typography variant="h6" color="textPrimary">{props.object.code}</Typography>
            </Box>
        </Header>
        {decrypted && <div className={style.content}> 
            {
                model.type === "File" && <FileObjectView object={props.object} data={decrypted}/>
            }
            {
                model.type !== "File" && Object.keys(decrypted).map((k) => {
                    let descriptor = model.properties[k];
                    if (!decrypted[k]) {
                        return <React.Fragment key={k}></React.Fragment>
                    }
                    return <Paper elevation={4} key={k}>
                        <ObjectPropertyUI descriptor={descriptor} property={decrypted[k]} />
                    </Paper>
                })
            }
        </div>}
        <Box paddingY={2} className={style.object_actions} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            {
                RuleMatcher.canWriteKey(collab) &&
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className={style.btn_action}
                    onClick={() => history.push(Routes.updateObject(props.object._id))}
                    startIcon={<Pen />}>
                    {t("buttons.updateObject")}
                </Button>
            }
            {
                RuleMatcher.canDeleteKey(collab) &&
                <Button
                    size="small"
                    variant="contained"
                    color="error"
                    style={{
                        marginLeft: '8px'
                    }}
                    onClick={(ev) => setConfirmDeleteAnchor(ev.currentTarget as HTMLButtonElement)}
                    startIcon={<Trash />}>
                    {t("buttons.deleteObject")}
                </Button>
            }
        </Box>
        <Box padding={2} paddingLeft={1} className={style.meta_wrapper}>
            <Typography variant="caption" color="textSecondary">{t("metadata")}</Typography>
            <Box className={style.content}>
                { props.object.createdAt && <Typography variant="body2" color="textSecondary">{t("dates.added", { date: Intl.DateTimeFormat("fr-FR").format(Date.parse(props.object.createdAt)) })}</Typography> }
                { props.object.createdAt && <Typography variant="body2" color="textSecondary">{t("dates.lastUpdate", { date: Intl.DateTimeFormat("fr-FR").format(Date.parse(props.object.updatedAt)) })}</Typography> }
            </Box>
        </Box>
        <Dialog open={Boolean(confirmDeleteAnchor)} onClose={() => setConfirmDeleteAnchor(undefined)} maxWidth="md">
            <DialogTitle>{t("headers.object.delete")}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{t("objectDeletionWarning1")}</Typography>
                <Typography variant="body1">{t("objectDeletionWarning2")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDeleteAnchor(undefined)} variant="text" color="primary">{t("buttons.cancel")}</Button>
                <Button onClick={() => onDelete()} variant="text" color="primary">{t("buttons.deleteNow")}</Button>
            </DialogActions>
        </Dialog>
    </div>
}

function ObjectPropertyUI(props: { property: string, descriptor: DataProperty }) {
    const [copied, setCopied] = React.useState(false);
    const [visible, setVisible] = React.useState(!Boolean(props.descriptor.sensible));

    const { t } = useTranslation();

    function copyToClipboard() {
        navigator.clipboard.writeText(props.property)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 5000);
            })
    }


    React.useEffect(() => {
        if (!props.descriptor.sensible) {
            setVisible(true);
        }
    }, [props.descriptor.sensible]);

    function show() {
        setVisible(true);
        setTimeout(() => {
            setVisible(false);
        }, 5000);
    }

    let iconSize = 18

    return <Box padding={1} margin={1} className={style.property} onClick={() => copyToClipboard()}>
        <Typography variant="body1" fontWeight={200} className={style.title}>{props.descriptor.label}</Typography>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            {
                visible && <Fade>
                    <Typography variant="body2" fontWeight={600} style={{ background: "var(--palette-background-default)", flexGrow: 1, padding: '8px', borderRadius: '4px' }}>
                        {props.property}
                    </Typography>
                </Fade>
            }
            {
                !visible &&
                <Typography variant="caption" fontWeight={200}>
                    {t('buttons.clickToShow')}
                </Typography>
            }
            <div className={style.actions}>
                <IconButton onClick={copyToClipboard}>
                    {copied ? <Check size={iconSize} /> : <Clipboard size={iconSize} />}
                </IconButton>
                {
                    props.descriptor.sensible &&
                    <IconButton onClick={visible ? () => setVisible(false) : show}>
                        {visible ? <EyeClosed size={iconSize} /> : <Eye size={iconSize} />}
                    </IconButton>
                }
            </div>
        </Box>
    </Box>
}


export const SingleObjectById = observer(() => {
    const match = useRouteMatch();
    let id = (match.params as any).objectId as string;
    let object = appState.vaultState.objects.find((o) => o._id === id);
    const history = useHistory()
    if (object) {
        return <SingleObject object={object} onDeleted={() => { history.replace(Routes.box(object?.vault || "")) }} />
    }
    return <></>
});