import React, { PropsWithChildren } from "react";
import { withFeeds, WithFeedsProps } from "./FeedsContext";
import { INotification, SignalType } from "../core";
import { appState } from "../state/AppState";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function NotificationWatcher(props: WithFeedsProps & PropsWithChildren<{}>) {
    const {t} = useTranslation();
    React.useEffect(() => {
        if (props.feedClient) {
            let client = props.feedClient;
            if (client) {
                client.on("notification", (notification) => {
                    if (notification.target.guid !== appState.profileState.profile?._id) {
                        toast.info( t("notificationResume", { length: "une", end: "" }) || "");
                    }

                    let type: SignalType = notification.type;

                    appState.signalState.appendSignal(notification);

                    if (type === "vault:member:invited") {
                        appState.vaultState.appendPendingInvite(notification.what);
                    }

                    if (type === "vault:member:joined") {
                        appState.vaultState.appendVault(notification.what);
                    }

                    else if (["vault:invitation:accepted", "vault:invitation:declined", "vault:invitation:cancelled"].includes(type)) {
                        appState.vaultState.removePendingInvite(notification.what._id);
                    }

                    else if (type === "vault:created") {
                        appState.vaultState.appendVault(notification.what);
                    }

                    else if (type === "vault:deleted") {
                        appState.vaultState.removeVault(notification.what._id);
                    }

                    else if (type === "vault:metadata:updated") {
                        appState.vaultState.appendVault(notification.what);
                    }

                    else if (type === "vault:content:created") {
                        appState.vaultState.appendObject(notification.what);
                    }

                    else if (type === "vault:content:updated") {
                        appState.vaultState.appendObject(notification.what);
                    }

                    else if (type === "vault:content:deleted") {
                        appState.vaultState.removeObject(notification.what._id);
                    }
                })

                client.on("unseen_notifications", (notifs: INotification[]) => {
                    appState.signalState.appendSignal(...notifs);
                })
                client.emit('get_notifications', { limit: 200, skip: 0 });
                return () => {
                    client
                        .off("unseen_notifications")
                        .off("notification");

                }
            }
        }
    }, [props.feedClient]);

    return <>
        {
            props.children
        }
    </>
}

export default withFeeds( observer(NotificationWatcher) );
