import { CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import dropFile from "../assets/drop-file.json";
import { File, Upload, X } from "phosphor-react";
import { bestSizeRep } from "../core/utils";
import { AWS_BUCKET_NAME, s3Client } from "../core/config";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import { Fade } from "react-awesome-reveal";
import { Upload as AwsUpload } from "@aws-sdk/lib-storage";
import JSZip from "jszip";
import { Encryption } from "../core/Encryption";
import { AesEncryptionScheme } from "./ObjectEncryption";

interface FileObjectProps {
    scheme: AesEncryptionScheme;
    fileKey?: string; /// in case of update
    onUploaded(data: any): any;
}

export function FileObjectInput(props: FileObjectProps) {
    const [busy, setBusy] = useState(false);
    const [fileKey, setFileKey] = useState(props.fileKey || `objects/${nanoid(28)}`);

    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);

    const fileContentToUpload = useCallback(async () => {
        if (files.length === 0) {
            return undefined;
        }

        if (files.length === 1) {
            return files[0].arrayBuffer()
                .then((arr) => {
                    return { array: arr, type: files[0].type }
                })
        }
        let zip = new JSZip();
        files.forEach((file) => {
            zip.file(file.name, file);
        })
        return zip.generateAsync({
            type: "arraybuffer",
        })
            .then((arr) => {
                return { array: arr, type: 'application/zip' }
            })
    }, [files]);

    const getEncryptedFileContentToUpload = useCallback(async () => {
        let fileContent = await fileContentToUpload();
        if (!fileContent) {
            return undefined;
        }

        toast.info(t("toast.encryption.ongoing"));
        let {key, iv} = props.scheme

        return new Encryption().encryptUint8Array(new Uint8Array(fileContent.array), { key,iv })
            .then((arr) => {
                toast.info(t("toast.encryption.done"));
                return { type: fileContent?.type, array: arr };
            })
    }, [fileContentToUpload, props.scheme, t]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles([...files, ...acceptedFiles]);
    }, [files]);

    const dropzone = useDropzone({ onDrop, multiple: true });

    const onRemoveFile = useCallback((file: File) => {
        let nextFiles = files.filter((f) => f !== file);
        setFiles(nextFiles);
    }, [files]);

    const launchUpload = useCallback(async () => {
        let archive = await getEncryptedFileContentToUpload();
        if (archive) {
            let upload = new AwsUpload({
                client: s3Client,
                params: {
                    Bucket: AWS_BUCKET_NAME,
                    Key: fileKey,
                    Body: archive.array
                }
            })

            setBusy(true);
            toast.info(t("toast.upload.busy"));
            upload.done().then((data) => {
                toast.success(t("toast.upload.done"));
                if(archive) {
                    props.onUploaded({
                        type: archive.type,
                        uploadPath: fileKey,
                        size: archive.array.byteLength,
                    })
                }
            })
                .catch((err) => {
                    if (err.message) {
                        toast.error(err.message)
                    }
                    else {
                        toast.error(t("toast.upload.failed"));
                    }
                })
                .finally(() => {
                    setBusy(false);
                })
        }

    }, [fileKey, getEncryptedFileContentToUpload, props, t]);

    return <Paper>
        <Box padding={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" {...dropzone.getRootProps()}>
            <input {...dropzone.getInputProps()} />
            <Player src={dropFile} autoplay loop={true} style={{ width: 96, height: 96 }} />
            <Typography variant="caption" color="textSecondary" style={{ marginTop: '8px' }}>{t("dropYourFilesHere")}</Typography>
            <Typography variant="caption" color="textSecondary" style={{ marginTop: '8px' }}>{t("zippingAlert")}</Typography>
        </Box>
        <Box padding={1}>
            <List dense>
                {
                    files.map((f, i) => {
                        return <ListItem dense key={f.size + "" + i}>
                            <ListItemAvatar>
                                <File size={24} />
                            </ListItemAvatar>
                            <ListItemText primary={f.name} secondary={`${f.type}, ${bestSizeRep(f.size)}`} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => onRemoveFile(f)}>
                                    <X size={18} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    })
                }
            </List>
        </Box>
        {files.length > 0 && <Box padding={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            {!busy && <Fade>
                <IconButton onClick={launchUpload} style={{
                    border: '1px solid var(--palette-primary-300)'
                }}>
                    <Upload />
                </IconButton>
            </Fade>}
            {busy && <Fade>
                <CircularProgress style={{ width: '44px' }} variant="indeterminate" />
            </Fade>}
        </Box>}
    </Paper>
}