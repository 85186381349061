import { makeAutoObservable } from "mobx";
import { IUser } from "../core";
import { AuthClient } from "../core/AuthClient";
import { uploadSingleBlob } from "../core/utils";

class ProfileState {
    profile?: IUser;
    updating: boolean;

    constructor() {
        makeAutoObservable(this)
        this.updating = false;
    }

    setProfile(profile: IUser) {
        this.profile = profile;
    }

    get isConnected() {
        return this.profile !== null && this.profile !== undefined;
    }

    get fullName() {
        if (this.profile) {
            return `${this.profile.name.first} ${this.profile.name.last}`
        }
        return "";
    }

    async changeProfilePicture(file: File) {
        let previousUrl = "";
        this.updating = true;
        return uploadSingleBlob(file, 'picture')
            .then((url) => {
                if(this.profile){
                    previousUrl = this.profile.pictureUrl || "";
                    this.profile.pictureUrl = url;
                    return new AuthClient()
                        .updateProfile(this.profile)
                }
            })
            .then((update) => {
                if (update) {
                    //props.enqueueSnackbar("Photo de profil mise à jour", { variant: "success" });
                }
            })
            .catch((err) => {
                console.log(err);
                if(this.profile){
                    this.profile.pictureUrl = previousUrl;
                }
                //props.enqueueSnackbar("Echec de mise à jour", { variant: "error" });
            })
            .finally(() => {
                this.updating = false;
            })
    }

    removeProfile() {
        this.profile = undefined;
    }
}

export default ProfileState;