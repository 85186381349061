import { makeAutoObservable } from "mobx";
import { nanoid } from "nanoid";
import { AuthClient } from "../core/AuthClient";
import { DataModel } from "../core/common";
import { VaultClient } from "../core/VaultClient";
import AccessKeyState from "./AccessKeyState";
import ProfileState from "./ProfileState";
import SignalState from "./SignalState";
import VaultState from "./VaultState";

export class AppState {
    initializing: boolean;
    profileState: ProfileState;
    vaultState: VaultState;
    accessKeyState:AccessKeyState;
    signalState: SignalState;
    models: {[key:string]: DataModel};
    search: string;

    constructor() {
        makeAutoObservable(this);
        this.profileState = new ProfileState();
        this.vaultState = new VaultState();
        this.accessKeyState = new AccessKeyState();
        this.signalState = new SignalState();
        this.models = {};
        this.search = "";
        this.initializing = true;
    }

    setSearch(s: string) {
        this.search = s;
    }

    async init(){
        this.initializing = true
        return new VaultClient().listModels()
        .then((models => {
            this.models = models;
        }))
        .then(() => {
            return new AuthClient().bootApp()
            .then((data) => {
                if(data.profile) {
                    this.profileState.setProfile(data.profile);
                }
                if(data.vaults) {
                    this.vaultState.setVaults(data.vaults);
                }
                if(data.invitations) {
                    this.vaultState.setInvitations(data.invitations);
                }
                if(data.keys) {
                    this.vaultState.setObjects(data.keys);
                }
            })
        })
        .finally(() => {
            this.initializing = false;
        })
    }
}

export const appState = new AppState();
(window as any).appState = appState;