export interface KeyValue {
    [key: string]: any;
}

export interface Identifiable {
    _id: string;
}

export interface Insertable {
    createdAt: string;
}

export interface Updatable {
    updatedAt: string;
}

export function isDev() {
    let hostname = window.location.hostname;
    if (hostname.startsWith("localhost") || hostname.startsWith("sandbox")) {
        return true;
    }
    return false;
}

export function getBaseUrl() {
    if(true) {
        return "https://zone51.softwaiz.co";
    }
    /*if (isDev()) {
        return "https://sandbox.zone51.app";
    }
    else {
        return "";
    }*/
}

function getApiUrl() {
    return getBaseUrl()+"/api";
}

export function getWsUrl() {
    return "https://feeds.zone51.app";
}

export const API_URL = getApiUrl();

export interface IName {
    first: string;
    last: string;
}

export type Gender = "male" | "female";
export type UserStatus = "active" | "disabled";

export interface IUser extends Identifiable, Insertable, Updatable {
    gender: Gender;
    name: IName;
    email: string;
    phone?: string;
    verified: boolean;
    status: UserStatus;
    pictureUrl?: string;
}

export interface IUserAuth extends Identifiable, Insertable, Updatable {
    userId: string;
    email: string;
    passwordHash: string;
}

export interface IUserSession extends Identifiable, Insertable, Updatable {
    userId: string;
    connectedAt: number;
    ip?: string;
}

export interface IUserVault extends Identifiable, Insertable, Updatable {
    ownerId: string;
    codeName: string;
    label: string;
    description: string;
    collaborators?: IVaultCollaborator[];
}

export type ICollabRole = "owner" | "admin" | "maintainer" | "reader";

export interface IVaultCollaborator {
    user: string;
    role: ICollabRole;
}

export interface IVaultKey extends Identifiable, Insertable, Updatable {
    vault: string;
    code: string;
    description?: string;
    data: string;
    format: string;
    encrypted?: boolean;
    encryption?: string;
    signature?: string;
}

export interface IAccessKey extends Identifiable, Insertable, Updatable {
    hash: string;
    userId: string;
    comment: string;
    keys: IAccessKeyScope[];
}

export interface IAccessKeyScope {
    vaultId: string;
    keyId: string | "all";
}

export interface IVaultInvitation extends Identifiable, Insertable, Updatable {
    host: {
        id: string;
        picture: string;
        fullName: string;
        email: string;
    };
    guest: {
        id: string;
        picture: string;
        fullName: string;
        email: string;
    };
    vault: {
        id: string;
        label: string;
        description: string;
    }
    role: ICollabRole;
}


export type SignalType = 
    "vault:created" |
    "vault:deleted" |
    "vault:metadata:updated" |
    "vault:content:created" |
    "vault:content:updated" |
    "vault:content:deleted" |
    "vault:member:invited" |
    "vault:member:joined" |
    "vault:invitation:accepted" |
    "vault:invitation:declined" |
    "vault:invitation:cancelled";

export interface IRawSignal {
    target: {
        type: "user";
        guid: string;
    },
    who: {
        type: "user";
        guid: string;
    },
    type: SignalType;
    what: any;
}

export type INotification =  IRawSignal & Identifiable & Insertable & Updatable & { seen: boolean };
