import React from "react";
import { io, Socket } from "socket.io-client";
import { getWsUrl } from "../core";

export function connectToFeedServer(token: string) {
    return io(getWsUrl(), {
        path: "/",
        transports: ["websocket", "polling"],
        query: { token }
    })
}

const FeedsContext = React.createContext<Socket | undefined>(undefined);

export function useRealtimeFeeds() {
    return React.useContext(FeedsContext);
}

interface ProviderProps {
    token: string;
    children: React.ReactNode;
}

export function FeedsProvider(props: ProviderProps) {
    const [ioClient, setIoClient] = React.useState<Socket>();

    React.useEffect(() => {
        let io = connectToFeedServer(props.token);
        setIoClient(io);
    }, [props.token]);

    return <FeedsContext.Provider value={ioClient}>
        {
            props.children
        }
    </FeedsContext.Provider>
}

export interface WithFeedsProps {
    feedClient?: Socket;
}

type Props<T = {}> = WithFeedsProps & T;

export function withFeeds<T>(WrappedComponent: React.ComponentType<Props<T>>): React.ComponentType<T> {
    return class extends React.Component<T, {}> {
        render() {
            return (
                <FeedsContext.Consumer>
                    { io => <WrappedComponent {...this.props} feedClient={io} />}
                </FeedsContext.Consumer>
            );
        }
    }
}