import { Avatar, Box, Button, Chip, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import React from "react";
import { User, UserPlus } from "phosphor-react";
import { useHistory } from "react-router";
import { IUser, IUserVault } from "../core";
import { AuthClient } from "../core/AuthClient";
import RuleMatcher from "../core/RuleMatcher";
import { appState } from "../state/AppState";
import { Header } from "./Header";
import { useTranslation } from "react-i18next";


const styles = makeStyles((t) => {
    return {
        root: {
            borderRadius: '46px'
        },

        chipRoot: {
            color: 'white'
        }
    }
})

const WhiteLabelChip = withStyles({
    label: {
        color: 'white'
    }
})(Chip);

interface Props {
    scope: IUserVault;
}

function ScopeCollaborators(props: Props) {
    const [owner, setOwner] = React.useState<IUser>();
    const [userProfiles, setUserProfiles] = React.useState<IUser[]>([]);
    const profileState = appState.profileState;
    const collab = React.useMemo(() => RuleMatcher.fromScope(props.scope, profileState?.profile?._id || ""), [profileState]);

    const router = useHistory();
    const themed = styles();
    const {t} = useTranslation();

    React.useEffect(() => {
        setUserProfiles([]);
        setOwner(undefined);
    }, [props.scope]);

    React.useEffect(() => {
        if (props.scope.collaborators && props.scope.collaborators.length > 0) {
            let client = new AuthClient()
            if (profileState.profile && profileState.profile._id !== props.scope.ownerId) {
                client
                    .getProfile(props.scope.ownerId)
                    .then((profile) => {
                        setOwner(profile);
                    })
            }
            client
                .bulkGetProfiles(props.scope.collaborators.map((c) => c.user))
                .then((profiles) => {
                    setUserProfiles(profiles);
                })
        }
    }, [profileState.profile, props.scope]);

    return <>
        <Box width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box flexGrow={1}>
                    <Typography variant="h6" color="var(--palette-text-primary)">{t("headers.collabs")}</Typography>
                </Box>
                <Box paddingLeft={1}>
                    {
                        RuleMatcher.canAddCollab(collab) &&
                        <Tooltip title={t("collabsHint") || ""}>
                            <Button size="small" className={themed.root} variant="contained" color="primary" startIcon={<UserPlus fill="white" />} onClick={() => router.push(`/home/box/${props.scope._id}/invite-members`)}>
                                {t("buttons.invite")}
                            </Button>
                        </Tooltip>
                    }

                </Box>
            </Box>
            <Box width="100%" paddingTop={1}>
                <List>
                    {
                        owner &&
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <>
                                        {
                                            (!owner.pictureUrl) &&
                                            <User />
                                        }
                                        {
                                            (owner.pictureUrl) &&
                                            <img src={owner.pictureUrl} alt={"Profil " + owner.name.first} style={{ width: '64px', height: '64px', objectFit: "cover" }} />
                                        }
                                    </>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${owner.name.first} ${owner.name.last}`}
                                secondary={owner.email} />
                            <ListItemSecondaryAction>
                                <WhiteLabelChip color="primary" label={t("roles.owner")} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    }
                    {
                        userProfiles.map((p) => {
                            let role = props.scope.collaborators?.find((c) => c.user === p._id);
                            return <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <>
                                            {
                                                (!p.pictureUrl) &&
                                                <User />
                                            }
                                            {
                                                (p.pictureUrl) &&
                                                <img src={p.pictureUrl} alt={"Profil " + p.name.first} style={{ width: '64px', height: '64px', objectFit: "cover" }} />
                                            }
                                        </>
                                    </Avatar>
                                </ListItemAvatar>
                                {
                                    profileState.profile &&
                                    <ListItemText
                                        primary={`${p.name.first} ${p.name.last} ${profileState.profile._id === p._id ? " (Moi)" : ""}`}
                                        secondary={p.email} />
                                }
                                <ListItemSecondaryAction>
                                    {
                                        role &&
                                        <WhiteLabelChip color="primary" label={t("roles."+role.role)} />
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        })
                    }
                    {
                        userProfiles.length === 0 && !owner &&
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" padding={2} minHeight="46px">
                            <Typography variant="body2" color="var(--palette-text-disabled)">{t("boxes.isPrivate")}</Typography>
                        </Box>
                    }
                </List>
            </Box>
        </Box>
    </>
}

export default observer(ScopeCollaborators);