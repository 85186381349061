import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

export const AUTH0_DOMAIN = "zone51.us.auth0.com"
export const AUTH0_CLIENT_ID = "9oAYKLRiUQy7wpfCo1msssWgdNkndFUH"

export const ID_TOKEN_EXPIRE = 35550 /// in seconds

export const AWS_BUCKET_NAME  = "datastore.datakit"
export const AWS_REGION = 'eu-west-3';
export const AWS_IDENTITY_POOL_ID = 'eu-west-3:60748014-d79f-4484-b537-642a01c79ed4';

export const s3Client = new S3Client({
    region: AWS_REGION,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
            region: AWS_REGION
        }),
        identityPoolId: AWS_IDENTITY_POOL_ID
    })
})
