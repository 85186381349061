import { Box, Button, List as MuiList, ListItemButton, ListItemText, Paper, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { IUserVault } from "../core";
import { appState } from "../state/AppState";
import { Plus } from "phosphor-react";
import { useBoxObjects, useSearch } from "../state/state-hooks";
import { Void } from "./Void";

const styles = makeStyles((t) => {
    return {
        root: {
            borderRadius: '46px'
        }
    }
})

function BoxObjects(props: { box: IUserVault }) {
    const router = useHistory();
    const themed = styles();
    const search = useSearch();
    const objects = useBoxObjects(props.box._id).filter((obj) => {
        return !Boolean(search) || (obj.code.toLowerCase().includes(search.toLowerCase()))
    })

    return <Box width="100%">
        <Toolbar>
            <Box flexGrow={1}>
                <Typography variant="h6" color="var(--palette-text-primary)">Objets conservés</Typography>
            </Box>
            <Box paddingLeft={1} paddingRight={1}>
                <Button size="small" className={themed.root} variant="contained" startIcon={<Plus fill="white" />} onClick={() => router.push(`/home/create-object?box=${props.box._id}`)}>Conserver un objet</Button>
            </Box>
        </Toolbar>
        <Box padding={1}>
            {objects.length > 0 && <Paper elevation={8}>
                <MuiList disablePadding>
                    {
                        objects.map((obj, i, arr) => {
                            let model = appState.models[obj.format];
                            return <ListItemButton dense divider={i < arr.length - 1} key={obj._id} onClick={() => router.push(`/home/box/${obj.vault}/objects/${obj._id}`)}>
                                <ListItemText
                                    primary={obj.code}
                                    secondary={`${model.label}, ${Object.keys(model.properties).length} propriétés`}
                                    primaryTypographyProps={{
                                        fontWeight: 600,
                                        fontSize: '14px !important'
                                    }}
                                    secondaryTypographyProps={{
                                        fontWeight: 200,
                                        fontSize: '12px !important'
                                    }}
                                />
                            </ListItemButton>
                        })
                    }
                </MuiList>
            </Paper>}
            {
                objects.length === 0 && !search && <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" padding={4} minHeight={120}>
                    <Void title="Aucun objet n'est conservé dans ce coffre." />
                </Box>
            }
            { search && objects.length === 0 && <Void title="Aucun objet ne correspond a cette recherche."/> }
        </Box>
    </Box>
}


export default observer(BoxObjects);