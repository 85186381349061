import { Box, Container, Hidden, IconButton, Toolbar, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "../ui/Logo";
import SingleBoxPng from "../assets/single_box.png";
import { blueGrey } from "@material-ui/core/colors";
import { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { List, X } from "phosphor-react";


function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return <Box className="l-header">
        <Toolbar className="bar">
            <Container>
                <Box padding={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box className="logo-wrapper">
                        <Logo size={56} />
                        <Typography variant="h5">DataKit</Typography>
                    </Box>
                    <Box>
                        <Hidden smUp>
                            <IconButton onClick={() => setMobileMenuOpen(true)}>
                                <List fill="white" />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            <NavLink className="menu-item" to="/auth/signin">
                                <span className="text">Se connecter</span>
                                <span className="border"></span>
                            </NavLink>
                            <NavLink className="menu-item" to="/auth/signup">
                                <span className="text">S'inscrire</span>
                                <span className="border"></span>
                            </NavLink>
                        </Hidden>
                    </Box>
                </Box>
                <Hidden smUp>
                    <Box component={"nav"} data-open={mobileMenuOpen} padding={2} className="menu-floating">
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Box className="logo-wrapper">
                                <Logo size={28} />
                                <Typography variant="h6">DataKit</Typography>
                            </Box>
                            <IconButton onClick={() => setMobileMenuOpen(false)}>
                                <X size={24} fill={blueGrey[500]} />
                            </IconButton>
                        </Box>
                        <Box paddingY={1} className="items">
                            <NavLink className="menu-item" to="/auth/signin">
                                <span className="text">Se connecter</span>
                                <span className="border"></span>
                            </NavLink>
                            <NavLink className="menu-item" to="/auth/signup">
                                <span className="text">S'inscrire</span>
                                <span className="border"></span>
                            </NavLink>
                        </Box>
                    </Box>
                </Hidden>
            </Container>
        </Toolbar>
        <Box padding={4} className="header-content">
            <Container>
                <Box className="left">
                    <Box marginY={2}>
                        <Slide triggerOnce direction="up">
                            <Typography variant="h4">
                                Sécurisez vos données.
                            </Typography>
                        </Slide>
                    </Box>
                    <Box marginY={2}>
                        <Fade triggerOnce>
                            <Typography variant="body1">
                                Préoccupez-vous moins de mémoriser vos mots de passe avec DataKit.
                            </Typography>
                        </Fade>
                    </Box>
                </Box>
            </Container>
            <img className="img-single-box" src={SingleBoxPng} alt="single box" />
        </Box>
    </Box>
}

export default Header;