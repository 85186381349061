
export const Routes = {
    base: '/home',
    model(model: string) {
        return `${this.base}/model/${model}`
    },
    box(boxId: string) {
        return `${this.base}/box/${boxId}`
    },
    boxObjects(boxId: string) {
        return `${this.box(boxId)}/objects`
    },
    singleBoxObject(boxId: string, objectId: string) {
        return `${this.box(boxId)}/objects/${objectId}`
    },
    boxSettings(boxId: string) {
        return `${this.box(boxId)}/settings`
    },
    inviteMembers(boxId: string) {
        return `${this.box(boxId)}/invite-members`
    },
    settings(){
        return `${this.base}/settings`
    },
    invitations() {
        return `${this.base}/invitations`
    },
    createBox() {
        return `${this.base}/create-box`
    },
    createObject() {
        return `${this.base}/create-object`
    },
    updateBox(id: string) {
        return `${this.base}/update-box/${id}`
    },
    updateObject(id: string) {
        return `${this.base}/update-object/${id}`
    },
}