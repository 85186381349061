import React, { useCallback } from "react";
import { Avatar, Box, Chip, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { ICollabRole, IUser, IUserVault } from "../core";
import { Header } from "./Header";
import { appState } from "../state/AppState";
import { AuthClient } from "../core/AuthClient";
import { VaultClient } from "../core/VaultClient";
import { X, Check, User, PaperPlaneTilt } from "phosphor-react"
import { ClockLoader } from "react-spinners";
import { grey } from "@material-ui/core/colors";
import { RoundedButton } from "./RoundedButton";
import { withStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Void } from "./Void";
import { useSearch } from "../state/state-hooks";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

const CustomChip = withStyles({
    root: {
        margin: '4px',
    },
    label: {
        color: 'white'
    },
})(Chip);

interface Props {
    scope: IUserVault;
}

function InviteCollaborators(props: Props) {
    const [searching, setSearching] = React.useState(false);
    const [foundMatches, setFoundMatches] = React.useState<IUser[]>([]);
    const [selectedProfiles, setSelectedProfiles] = React.useState<IUser[]>([]);
    const [executing, setExecuting] = React.useState(false);
    const search = useSearch();
    const {t} = useTranslation();

    React.useEffect(() => {
        appState.setSearch("");
    }, []);

    const onSearch = useCallback(() => {
        if (!search || search.length === 0) {
            return;
        }
        let code = search.trim().toLowerCase();
        setSearching(true);
        new AuthClient()
            .searchUser(code)
            .then((matches) => {
                setSearching(false);
                setFoundMatches([...selectedProfiles, ...matches]);
            })
    }, [selectedProfiles, search])

    React.useEffect(() => {
        onSearch();
    }, [onSearch]);

    const selectProfile = useCallback((profile: IUser) => {
        if (selectedProfiles.find((u) => u._id === profile._id)) {
            return;
        }
        let clone = [...selectedProfiles];
        clone.push(profile);
        setSelectedProfiles(clone);
    }, [selectedProfiles]);

    const deselectProfile = useCallback((profile: IUser) => {
        if (!selectedProfiles.find((u) => u._id === profile._id)) {
            return;
        }

        let clone = selectedProfiles.filter((u) => u._id !== profile._id);
        setSelectedProfiles(clone);
    }, [selectedProfiles])

    const isProfileSelected = useCallback((p: IUser) => {
        return Boolean(selectedProfiles.find((s) => s._id == p._id));
    }, [selectedProfiles])

    const onProfileSelected = useCallback((profile: IUser) => {
        let selected = selectedProfiles.find((p) => p._id === profile._id);
        if (selected) {
            deselectProfile(profile);
        }
        else {
            selectProfile(profile);
        }
    }, [deselectProfile, selectProfile, selectedProfiles])

    const setupCollaborators = useCallback((collabs: IUser[]) => {
        let mapped: { user: string, role: ICollabRole }[] = collabs.map((collab) => {
            return {
                user: collab._id,
                role: "maintainer"
            }
        });

        if (props.scope) {
            toast.info(t("ongoing"))
            setExecuting(true);
            new VaultClient()
                .addCollabs(props.scope?._id, mapped)
                .then((updatedScope) => {
                    if (updatedScope) {
                        setSelectedProfiles([]);
                        toast.success(t("toasts.invites.send.success"))
                    }
                    return undefined;
                })
                .catch((err) => {
                    toast.warn(t("toasts.invites.send.error"))
                })
                .finally(() => {
                    setExecuting(false);
                })
        }
    }, [props.scope, t])

    return <Box width="100%">
        <Box>
            <Header showReturn>
                <Typography variant="h6" color="var(--palette-text-primary)">{t("headers.invitations.send")}</Typography>
            </Header>
            <Box padding={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                {appState.search.length === 0 && foundMatches.length === 0 && <Typography variant="body2" color="var(--palette-text-disabled)">{t("invitationSearchHint")}</Typography>
                }
            </Box>
            {selectedProfiles.length > 0 && <Box paddingTop={1} paddingBottom={1}>
                {
                    selectedProfiles.map((s) => {
                        return <CustomChip
                            key={s._id}
                            avatar={<Avatar>
                                {
                                    (!s.pictureUrl) &&
                                    <User fill="white" />
                                }
                                {
                                    (s.pictureUrl) &&
                                    <img src={s.pictureUrl} alt={s.name.first} style={{ width: '64px', height: '64px', objectFit: "cover" }} />
                                }
                            </Avatar>}
                            color="primary"
                            onDelete={() => { deselectProfile(s) }}
                            deleteIcon={<X size={18} fill="white" />}
                            label={s.name.first + " " + s.name.last} />
                    })
                }
            </Box>}
            <Box paddingTop={1} paddingBottom={1}>
                {
                    searching &&
                    <Box paddingTop={2} paddingBottom={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <ClockLoader size={32} color={grey[500]} />
                    </Box>
                }
                {
                    !(foundMatches.length > 0) && !searching && appState.search.length > 0 &&
                    <Fade>
                        <Box paddingTop={1} paddingBottom={1} textAlign="center" minHeight={180}>
                            <Void title="Aucun utilisateur trouvé." />
                        </Box>
                    </Fade>
                }
                <List>
                    {
                        foundMatches && !searching && foundMatches.filter((u) => !Boolean(selectedProfiles.includes(u))).map((match) => {
                            let selected = Boolean(selectedProfiles.find((p) => p._id === match._id));
                            return <ListItemButton dense selected={selected} key={match._id} onClick={() => onProfileSelected(match)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {
                                            (!match.pictureUrl) &&
                                            <User color={"var(--palette-primary-main)"} />
                                        }
                                        {
                                            (match.pictureUrl) &&
                                            <img src={match.pictureUrl} alt={"Profil " + match.name.first} style={{ width: '64px', height: '64px', objectFit: "cover" }} />
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={match.name.first + " " + match.name.last}
                                    secondary={match.email}
                                    primaryTypographyProps={{
                                        color: 'var(--palette-text-primary)',
                                        fontSize: '16px !important',
                                        fontWeight: 600
                                    }}
                                    secondaryTypographyProps={{
                                        color: 'var(--palette-text-secondary)',
                                        fontSize: '14px !important',
                                        fontWeight: 400
                                    }}/>
                                <ListItemSecondaryAction>
                                    <RoundedButton
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        startIcon={
                                            <Check color={"var(--color-primary)"} />
                                        }
                                        disabled={selected}
                                        onClick={(ev) => onProfileSelected(match)}>
                                        {selected ? "Choisi" : "Choisir"}
                                    </RoundedButton>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        })
                    }
                </List>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                {
                    selectedProfiles.length > 0 && !searching &&
                    <RoundedButton disabled={foundMatches.length === 0 || executing} color="primary" variant="contained" endIcon={<PaperPlaneTilt fill="white" />} onClick={() => setupCollaborators(selectedProfiles)}>
                        Inviter {selectedProfiles.length === 1 ? selectedProfiles[0].name.first : "ces  utilisateurs"}
                    </RoundedButton>
                }
            </Box>
        </Box>
    </Box>
}

export default observer(InviteCollaborators);