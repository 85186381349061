import { makeAutoObservable } from "mobx";
import { IAccessKey, IUserVault } from "../core";
import { VaultClient } from "../core/VaultClient";

class AccessKeyState  {
    list: IAccessKey[];

    constructor() {
        makeAutoObservable(this);
        this.list = [];
    }

    async fetchList(){
        return new VaultClient().fetchAccessKeyList()
        .then((list) => {
            if(list){
                this.list = list;
            }
        })
        .catch((err) => {});
    }
}

export default AccessKeyState;