import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"
import { ICollabRole, IUser, IUserVault, IVaultKey } from "../core"
import { appState } from "../state/AppState"
import { SingleObjectById } from "./ObjectUI"
import styles from "../styles/Box.module.scss";
import { Redirect, Route, Switch, useHistory } from "react-router"
import { Box, Drawer, IconButton, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { VaultClient } from "../core/VaultClient"
import { NavLink } from "react-router-dom"
import BoxObjects from "./BoxObjects"
import InviteCollaborators from "./InviteCollaborators"
import { DotsThreeVertical, Gear } from "phosphor-react";
import { css } from "@emotion/css"
import { grey } from "@material-ui/core/colors"
import BoxSettings from "./BoxSettings"
import { Routes } from "../state/routes"
import { toast } from "react-toastify"
import { CirclesFour, Wrench } from "phosphor-react";
import { Fade } from "react-awesome-reveal"

const styled = (theme: Theme) => {
    return {
        bottomSheet: css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        `,
        bottomsheet_link: css`
            background: ${grey[50]};
            padding: 8px 16px;
            width: 100%;

            &:hover {
                background: ${theme.palette.secondary.main};
                color: white;

                span {
                    color: white;
                }
            }
        `,
        link_active: css`
            background: ${theme.palette.primary.main} !important;
            color: white;

            span {
                color: white;
            }
        `
    }
}

function BoxUI(props: { box: IUserVault }) {
    const objects = appState.vaultState.objects.filter((obj) => obj.vault === props.box._id)
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

    const theme = useTheme();
    const themed = styled(theme);

    const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    return <div className={styles.box}>
        <Box paddingY={2} className={styles.header}>
            <Typography variant="h5" className={styles.title} color="var(--palette-text-primary)" fontWeight="bold">{props.box.label}</Typography>
            {!matchDownSm && <div className={styles.actions}>
                <NavLink className={styles.link} activeClassName={styles.link_active} to={Routes.boxObjects(props.box._id)}>
                    <CirclesFour data-role="icon" size={24} color={theme.palette.text.primary} />
                    <span data-role="title" className={styles.text}>Objets</span>
                    <span data-role="underline" className={styles.border}></span>
                </NavLink>
                <NavLink className={styles.link} activeClassName={styles.link_active} to={Routes.boxSettings(props.box._id)}>
                    <Gear data-role="icon" size={24} color={theme.palette.text.primary} />
                    <span data-role="title" className={styles.text}>Paramètres</span>
                    <span data-role="underline" className={styles.border}></span>
                </NavLink>
            </div>}
            {
                matchDownSm && <>
                    <IconButton onClick={() => setBottomSheetOpen(true)}>
                        <DotsThreeVertical fill={theme.palette.primary.main} />
                    </IconButton>
                    <Drawer anchor="bottom" open={bottomSheetOpen} onClose={() => setBottomSheetOpen(false)}>
                        <Box className={themed.bottomSheet}>
                            <NavLink className={themed.bottomsheet_link} activeClassName={themed.link_active} to={`/home/box/${props.box._id}/objects`}>
                                <span className={styles.text}>Objets</span>
                                <span className={styles.border}></span>
                            </NavLink>
                            <NavLink className={themed.bottomsheet_link} activeClassName={themed.link_active} to={`/home/box/${props.box._id}/settings`}>
                                <span className={styles.text}>Paramètres</span>
                                <span className={styles.border}></span>
                            </NavLink>
                        </Box>
                    </Drawer>
                </>
            }
        </Box>
        <div className={styles.content}>
            <Switch>
                <Route path={Routes.singleBoxObject(props.box._id, ":objectId")}>
                    <Fade>
                        <SingleObjectById />
                    </Fade>
                </Route>
                <Route path={Routes.boxObjects(props.box._id)}>
                    <Fade>
                        <BoxObjects box={props.box} />
                    </Fade>
                </Route>
                <Route path={Routes.boxSettings(props.box._id)}>
                    <Fade>
                        <BoxSettings scope={props.box} />
                    </Fade>
                </Route>
                <Route path={Routes.inviteMembers(props.box._id)}>
                    <Fade>
                        <InviteCollaborators scope={props.box} />
                    </Fade>
                </Route>
                <Route path={Routes.box(props.box._id)}>
                    <Redirect to={Routes.boxObjects(props.box._id)} />
                </Route>
            </Switch>
        </div>
    </div>
}

export default observer(BoxUI)