import { Box, List as MuiList, ListItemButton, ListItemText, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { DataModel } from "../core/common";
import { useObjectsByModel, useSearch } from "../state/state-hooks";
import { Header } from "./Header";
import { Void } from "./Void";

function ModelUI(props: { model: DataModel }) {
    const search = useSearch();
    const {t} = useTranslation();
    const objects = useObjectsByModel(props.model.type).filter((obj) => {
        return !Boolean(search) || (obj.code.toLowerCase().includes(search.toLowerCase()))
    })

    const router = useHistory();
    return <Box paddingY={2}>
        <Header>
            <Typography variant="h5" color="var(--palette-text-primary)" fontWeight={600}>{props.model.label}</Typography>
        </Header>
        <Box paddingY={1}>
            { search && objects.length === 0 && <Void title={t("noObjectMatch")}/> }
            {objects.length > 0 && <Paper elevation={8}>
                <MuiList disablePadding>
                    {
                        objects.map((obj, i, arr) => {
                            return <ListItemButton dense divider={i < arr.length -1} key={obj._id} onClick={() => router.push(`/home/box/${obj.vault}/objects/${obj._id}`)}>
                                <ListItemText
                                    primary={obj.code}
                                    secondary={t("objectSecondary", {model: props.model.label, length: Object.keys(props.model.properties).length})}
                                    primaryTypographyProps={{
                                        fontWeight: 600,
                                        fontSize: '14px !important'
                                    }}
                                    secondaryTypographyProps={{
                                        fontWeight: 200,
                                        fontSize: '12px !important'
                                    }}
                                />
                            </ListItemButton>
                        })
                    }
                </MuiList>
            </Paper> }
        </Box>
    </Box>
}

export default observer(ModelUI);