import { Box, Typography } from "@material-ui/core";
import Logo from "../ui/Logo";

function Footer() {
    return <Box component="footer" className="footer">
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Logo size={64} />
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <Typography variant="h6">DataKit</Typography>
            </Box>
        </Box>
        <Box width="100%" padding={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Box paddingX={1}>
                <Typography variant="body2">Made with 💓 by Softwaiz Labs</Typography>
            </Box>
        </Box>
    </Box>
}

export default Footer;