import { Avatar, Box, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import { User } from "phosphor-react";
import { DotLoader } from "react-spinners";
import { appState } from "../state/AppState";
import FabPPUpdate from "./FabPPUpdate";

const styled = makeStyles((t) => {
    return {
        avatarWrapper: {
            position: 'relative',
        },
        avatar: {
            height: '100%',
            width: '100%',
        },
        floating: {
            position: 'absolute',
            bottom: 0,
            right: 0,
        }
    }
}
);

interface Props {
    allowUpdate?: boolean;
    sizeInPx: number;
}

function UserAvatar(props: Props) {
    const profileState = appState.profileState;

    const theme = useTheme();
    const styles = styled();

    return <Box className={styles.avatarWrapper}>
        <Avatar className={styles.avatar} style={{width:props.sizeInPx+"px", height: props.sizeInPx+"px"}}>
            {
                profileState.updating &&
                <DotLoader size={24} />
            }
            {
                (!profileState.updating && profileState.profile && !profileState.profile.pictureUrl) &&
                <User size={24} />
            }
            {
                (!profileState.updating && profileState.profile && profileState.profile.pictureUrl) &&
                <img src={profileState.profile.pictureUrl} alt="Profil" style={{ width: '100%', height: '100%', objectFit: "cover" }} />
            }
        </Avatar>
        {
            props.allowUpdate && <>
                <FabPPUpdate fabProps={{className: styles.floating}}/>
            </>
        }
    </Box>
}

export default observer(UserAvatar);