import { SEARCH_EXP } from "../core/search";
import BoxSearchProcessor from "./BoxSearchProcessor";
import ModelSearchProcessor from "./ModelSearchProcessor";


export function SearchProcessor(props: { search: string }) {
    if(props.search.match(SEARCH_EXP.box)) {
        return <BoxSearchProcessor search={props.search}/>
    }
    else if(props.search.match(SEARCH_EXP.model)) {
        return <ModelSearchProcessor search={props.search}/>
    }
    return <></>
}
