import { useCallback, useMemo, useState } from "react";
import { Box } from "@material-ui/system";
import { Button, Typography } from "@material-ui/core";
import { IVaultKey } from "../core";
import { useTranslation } from "react-i18next";
import { Download } from "phosphor-react";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { AWS_BUCKET_NAME, s3Client } from "../core/config";
import { Encryption } from "../core/Encryption";
import { ClockLoader } from "react-spinners";
import { bestSizeRep, downloadFile } from "../core/utils";
import { Fade } from "react-awesome-reveal";
import { toast } from "react-toastify";
import doneAnimation from "../assets/done.json";
import { Player } from "@lottiefiles/react-lottie-player";
import mime from "mime-types";

interface FileObjectData {
    uploadPath: string;
    size: number;
    type: string;
}

interface ViewProps {
    data: FileObjectData;
    object: IVaultKey;
}

export function FileObjectView(props: ViewProps) {
    const [busy, setBusy] = useState(false);
    const [done, setDone] = useState(false);
    const { t } = useTranslation();
    const [message, setMessage] = useState("");

    const decryptionKey = useMemo(() => {
        if (props.object.encryption) {
            return JSON.parse(props.object.encryption).key
        }
        return undefined;
    }, [props.object]);

    const onDownload = useCallback(async () => {
        let getCmd = new GetObjectCommand({
            Bucket: AWS_BUCKET_NAME,
            Key: props.data.uploadPath
        })

        setBusy(true);
        setMessage(t("toasts.download.busy"))
        let arrayBuffer = await s3Client.send(getCmd)
            .then(async (out) => new Response(out.Body as any).arrayBuffer())
            .catch((err) => {
                setMessage(t("toasts.download.failed"))
                return undefined;
            })

        if (decryptionKey && arrayBuffer) {
            setMessage(t("toasts.decryption.ongoing"))
            let decrypted = await new Encryption()
                .decryptUint8Array(new Uint8Array(arrayBuffer), decryptionKey);
                setMessage(t("toasts.decryption.done"));

            if (decrypted) {
                let blob = new Blob([decrypted.buffer], { type: props.data.type });
                let url = URL.createObjectURL(blob);

                let ext = mime.extension(props.data.type);

                downloadFile(url, `${props.object.code}${ext ? "."+ext: ""}`);
                URL.revokeObjectURL(url);
                setMessage(t("toasts.download.done"))
                toast.success(t("toasts.download.done"));
                setBusy(false);
                setDone(true);
                setTimeout(() => {
                    setDone(false);
                }, 15000);
            }
        }

    }, [decryptionKey, props.data.type, props.data.uploadPath, props.object.code, t]);

    return <Box paddingY={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {
            busy && <Fade>
                <ClockLoader color="var(--palette-text-secondary)" size={28} />
                <Box paddingY={1}>
                    <Typography variant="body1">{message}</Typography>
                </Box>
            </Fade>
        }
        {
            done && <Fade>
                <Player autoplay={true} loop={false} src={doneAnimation} style={{width: 96, height:96}}/>
            </Fade>
        }
        {decryptionKey && !(busy||done) && <Fade>
            <Button
                onClick={onDownload}
                fullWidth
                size="large"
                startIcon={<Download />}
                variant="outlined"
                color="inherit"
                style={{ color: 'var(--palette-text-secondary)' }}>
                {`${t('buttons.downloadFile')} ( ${bestSizeRep(props.data.size)} )`}
            </Button>
        </Fade>}
    </Box>
}