import { IUserVault, IVaultCollaborator } from ".";

const RuleMatcher = {
    fromScope(vault: IUserVault, userId: string):IVaultCollaborator|undefined {
        let peerRole = undefined;

        if (vault.ownerId === userId) {
            peerRole = { user: userId, role: "owner" } as IVaultCollaborator;
        }

        else if (vault.collaborators) {
            peerRole = vault.collaborators?.find((c) => c.user === userId) as IVaultCollaborator;
        }

        return peerRole;
    },
    canReadKey(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner", "admin", "maintainer", "reader"].includes(role.role));
    },
    canWriteKey(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner", "admin", "maintainer"].includes(role.role));
    },
    canDeleteKey(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner", "admin", "maintainer"].includes(role.role));
    },
    canUpdateScope(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner", "admin"].includes(role.role));
    },
    canDeleteScope(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner"].includes(role.role));
    },
    canAddCollab(role: IVaultCollaborator|undefined = undefined) {
        return Boolean(role && ["owner", "admin"].includes(role.role));
    }
}

export default RuleMatcher;