import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react";
import { PropsWithChildren, useLayoutEffect } from "react";
import { ID_TOKEN_EXPIRE } from "../core/config";
import { Routes } from "../state/routes";
import { useStoreState } from "../state/storeContext";
import Loading from "./Loading";

interface Props {}

type MProps = PropsWithChildren<Props>;

function ProtectedByAuth(props: MProps) {
    const appState = useStoreState();
    const { isLoading, isAuthenticated, user, getAccessTokenWithPopup, loginWithPopup, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    useLayoutEffect(() => {
        let localToken = localStorage.getItem("sessiontk");
        let expire = parseInt(localStorage.getItem("token_expire") || "0");
        let isValid = Date.now() < expire;

        if(isLoading) {
            return;
        }

        if(isAuthenticated && (!localToken || !isValid)) {
            getAccessTokenWithPopup({
                audience: `https://www.zone51.app/api`
            })
            .then(async(token) => {
                localStorage.setItem("sessiontk", token);
                let expire =  Date.now()+(ID_TOKEN_EXPIRE*1000)
                localStorage.setItem("token_expire", expire+"");
                await appState.init();
            })
        } 
        else if(isAuthenticated && localToken){
            appState.init();
        }
        else if (!isAuthenticated) {
            loginWithRedirect({redirectUri: window.location.origin+Routes.base});
        }
    }, [appState, getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, isLoading, loginWithRedirect]);

    return <>
        { isAuthenticated && !appState.initializing && props.children }
        {
            (isLoading || appState.initializing) &&
            <Box width="100vw" height="100vh" display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{background: 'var(--palette-background-default)'}}>
                <Loading />
            </Box>
        }
    </>
}

export default observer(ProtectedByAuth)