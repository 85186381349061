import { Box, Button, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { IVaultKey } from "../core";
import { VaultClient } from "../core/VaultClient";
import { appState } from "../state/AppState";
import { Header } from "./Header";
import KeyDataInput from "./KeyDataInput";
import { toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { Routes } from "../state/routes";
import { useDecryptedObject } from "../state/state-hooks";
import { AesEncryptionScheme, ObjectEncryptionScheme } from "./ObjectEncryption";
import { Encryption } from "../core/Encryption";
import { Fade } from "react-awesome-reveal";
import { FileObjectInput } from "./FileObjectInput";

interface Props { }

function UpdateObject(props: Props) {
    const [editingKey, setEditingKey] = React.useState<IVaultKey>();
    const [busy, setBusy] = React.useState(false);
    const keyData = useDecryptedObject(editingKey)
    const [update, setUpdate] = useState();
    const [encryptionScheme, setEncryptionScheme] = useState<AesEncryptionScheme>();
    const [uploadedData, setUploadedData] = useState(keyData);

    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();

    React.useEffect(() => {
        setUpdate(keyData);
        if(editingKey?.format === "File") {
            setUploadedData(keyData);
        }
    }, [keyData]);

    React.useEffect(() => {
        let keyId = (match.params as any).id;
        let single = appState.vaultState.objects.find((o) => o._id === keyId)
        if (single) {
            setEditingKey(single);
        }
    }, [match.params]);

    const isSubmissionDisabled = useMemo(() => {
        let is: any = Boolean(!editingKey) || Boolean(!keyData);
        is = Boolean(is) || (editingKey && editingKey.format === "File" && !uploadedData);
        return is;
    }, [editingKey, uploadedData, keyData]);

    const onSubmit = useCallback(async (ev: React.ChangeEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (update) {
            let data = {
                ...editingKey,
                code: ev.target.code.value,
                description: ev.target.description.value,
                data: JSON.stringify(update)
            }

            if (editingKey && encryptionScheme) {
                toast.info(t("ongoing"));

                setBusy(true);

                let encrypted = await new Encryption().encryptObject(data as any, encryptionScheme)

                new VaultClient()
                    .updateSingleKey(editingKey._id, encrypted)
                    .then((single) => {
                        setBusy(false);
                        if (single) {
                            toast.success(t("toasts.object.update.success"));
                            history.replace(Routes.box(editingKey.vault));
                        }
                        else {
                            toast.warn(t("toasts.object.update.error"));
                        }
                    })
            }
        }
    }, [editingKey, encryptionScheme, history, t, update])

    return <>
        {
            editingKey && keyData && <Box component="form" onSubmit={onSubmit}>
                <Header showReturn>
                    <Typography variant="h5" color="var(--palette-text-primary)">{editingKey.code}</Typography>
                </Header>
                <Box padding={1}>
                    <Box paddingY={1}>
                        <ObjectEncryptionScheme scheme={encryptionScheme} onChange={setEncryptionScheme} />
                    </Box>
                    <Box paddingY={1}>
                        <TextField
                            size="small"
                            name="code"
                            defaultValue={editingKey.code}
                            variant="outlined"
                            fullWidth
                            label={t("objectTitle.label")} />
                    </Box>
                    <Box paddingY={1}>
                        <TextField
                            size="small"
                            name="description"
                            defaultValue={editingKey.description}
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                            label={t("objectComment.label")}
                            helperText={t("objectComment.helper")} />
                    </Box>
                    {
                        update && editingKey.format !== "File" && <Fade>
                           <KeyDataInput
                        type={editingKey.format}
                        data={update}
                        onUpdated={setUpdate} />
                        </Fade>
                    }
                    {
                        update && editingKey.format === "File" && encryptionScheme && <Fade>
                            <Box paddingY={2}>
                                <FileObjectInput fileKey={uploadedData.uploadPath} scheme={encryptionScheme} onUploaded={setUploadedData} />
                            </Box>
                        </Fade>
                    }
                    <Box paddingY={1} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                        <Button
                            size="small"
                            type="submit"
                            endIcon={
                                busy ? <DotLoader size={24} color="var(--palette-action-active)" /> : undefined
                            }
                            variant="contained"
                            color="primary"
                            disabled={isSubmissionDisabled}>{t("buttons.updateObject")}</Button>
                    </Box>
                </Box>
            </Box>
        }
    </>
}

export default observer(UpdateObject);