import Axios from "axios";
import { API_URL, IUser } from ".";

export class AuthClient {

    async signup(request: any) {
        return Axios.post(
            `${API_URL}/users`,
            request
        )
            .then((res) => {
                if (res.data.success) {
                    return true;
                }
                return false;
            })
    }

    async signin(request: any) {
        return Axios.post(
            `${API_URL}/users/signin`,
            request
        )
            .then((res) => {
                if (res.data.success) {
                    localStorage.setItem("sessiontk", res.data.data);
                    return res.data.data;
                }
                else if(res.data.errors) {
                    throw {remote: true, message: res.data.message, errors: res.data.errors};
                }
                return false;
            })
    }

    async validateSignup(code: string) {
        return Axios.get(
            `${API_URL}/users/validate/${code}`
        )
            .then((res) => {
                if (res.data.success) {
                    return true;
                }
                return false;
            })
    }


    async signout() {
        localStorage.removeItem("sessiontk");
        return true;
    }
    
    async getProfile(id="me") {
        return Axios.get(
            `${API_URL}/users/${id}`,
            {
                headers: {
                    "Authorization": "auth0 " + localStorage.getItem("sessiontk")
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    return res.data.data as IUser;
                }
                return undefined;
            })
    }

    async bulkGetProfiles(ids: string[]) {
        return Axios.post(
            `${API_URL}/users/bulk`,
            ids,
            {
                headers: {
                    "Authorization": "auth0 " + localStorage.getItem("sessiontk")
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    return res.data.data as IUser[];
                }
                return [];
            })
    }

    async updateProfile(user: IUser) {
        return Axios.patch(
            `${API_URL}/users/me`,
            user,
            {
                headers: {
                    "Authorization": "auth0 " + localStorage.getItem("sessiontk")
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    return res.data.data as IUser;
                }
                return undefined;
            })
    }

    async searchUser(tags: string) {
        return Axios.post(
            `${API_URL}/users/match`,
            {search: tags},
            {
                headers: {
                    "Authorization": "auth0 " + localStorage.getItem("sessiontk")
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    return res.data.data as IUser[];
                }
                return [];
            })
    }

    async bootApp() {
        return Axios.get(
            `${API_URL}/boot`,
            {
                headers: {
                    "Authorization": "auth0 " + localStorage.getItem("sessiontk")
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    return res.data.data ;
                }
                return undefined;
            })
    }
}