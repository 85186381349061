import { css } from "@emotion/css";
import { Box, IconButton, InputBase, Theme, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { MagnifyingGlass, X } from "phosphor-react";
import { appState } from "../state/AppState";
import { Fade } from "react-awesome-reveal";

interface Props {
    placeholder: string;
    onSearch(kw: string): any;
    onCleared(): any;
}

const themable = (theme: Theme) => {
    return {
        wrapper: css`
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 46px;
        border-radius: 24px;
        background: #64646420;
    `,
        startIcon: css`
        padding: 8px;
        background: transparent;
        height: 40px;
        width: 40px;
        display: block;
        border-radius: 24px;
    `,
        input: css`
        flex-grow: 1;
        height: 100%;
        padding: 0px 16px;
        border-radius: 24px;
        background: transparent;
        font-family: 'Work Sans';
    `,
        endBtn: css`
        height: 46px;
        width: 46px;
    `,
    deleteBtn: css`
        height: 24px;
        width: 24px;
        margin-right: 4px;
    `
    }
}

export function SearchBar(props: Props) {
    const [search, setSearch] = useState(appState.search);
    const theme = useTheme();
    const styles = themable(theme);

    const handleKeyPress = useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
        if(ev.key.toLowerCase() === "enter") {
            props.onSearch(search);
        }
    }, [search,props]);

    const clearSearch = useCallback(() => {
        setSearch("");
    }, [])

    const launchSearch = useCallback(() => {
        if (search) {
            props.onSearch(search.trim());
        }
    }, [search,props])

    useEffect(() => {
        if(!search || search.length === 0) {
            props.onCleared();
        }
    }, [search, props]);

    return (
        <Box className={styles.wrapper}>
            <InputBase
                onKeyPress={handleKeyPress}
                className={styles.input}
                type="search"
                size="small"
                value={search}
                onChange={(ev) => setSearch(ev.currentTarget.value)}
                placeholder={props.placeholder} />
            {
                search.length > 0 && <Fade>
                    <IconButton size="small" onClick={clearSearch} className={styles.deleteBtn}>
                        <X size={16} color={theme.palette.action.active} />
                    </IconButton>
                </Fade>
            }
            {
                search.length > 0 && <Fade>
                    <IconButton size="small" onClick={launchSearch} className={styles.endBtn}>
                        <MagnifyingGlass size={24} color={theme.palette.action.active} />
                    </IconButton>
                </Fade>
            }
        </Box>
    );
}