import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Header } from "./Header";
import { InvitationList } from "./InvitationList";


function InvitationPage() {
    const {t} = useTranslation();
    return <Box>
        <Header showReturn>
            <Typography variant="h5" color="var(--palette-text-primary)">{t("headers.invitations.view")}</Typography>
        </Header>
        <Box>
            <InvitationList/>
        </Box>
    </Box>
}

export default InvitationPage;