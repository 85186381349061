import { API_URL, IUser, IVaultInvitation, IVaultKey } from ".";

export async function uploadSingleBlob(blob: Blob, name: string) {
    let form = new FormData();
    form.set(name, blob, 'picture');
    return fetch(
        API_URL + "/assets/",
        {
            method: "POST",
            body: form,
        }
    )
        .then((res) => res.json())
        .then((json) => {
            if (json.success) {
                return json.data[name] as string;
            }
            return "";
        })
}

export function randomScopeDeletionCode(length = 6) {
    let str = 'qwertyuiop123456asdfghjkl7890zxcvbnm';
    let gen = '';
    for (let i = 0; i < length; i++) {
        let random_pos = Math.floor(Math.random() * (str.length - 1));
        gen += str.charAt(random_pos);
    }

    return gen;
}


export function inject(raw: string, props: any) {
    let final = raw;

    Object.keys(props).forEach((k) => {
        final = final.replaceAll(`%${k}%`, props[k]);
    })

    return final;
}

export function composeInvitationTitle(invitation: IVaultInvitation, iam: IUser) {
    let self_to_other = "Vous avez invité %guest% dans %space%";
    let other_to_self = "%host% vous a invité dans %space%";
    let other_to_other = "%host% a invité %guest% dans %space%";

    if (invitation.host.id === iam._id) {
        return inject(self_to_other, { guest: invitation.guest.fullName, space: invitation.vault.label });
    }

    else if (invitation.guest.id === iam._id) {
        return inject(other_to_self, { host: invitation.host.fullName, space: invitation.vault.label });
    }

    else {
        return inject(other_to_other, { host: invitation.host.fullName, guest: invitation.guest.fullName, space: invitation.vault.label });
    }
}

export function timeToMinutes(time: number) {
    return Math.floor(time / (60 * 1000));
}

export function timeToHours(time: number) {
    return Math.floor(time / (60 * 60 * 1000));
}

export function timeToDays(time: number) {
    return Math.floor(time / (24 * 60 * 60 * 1000));
}

export function composeDateTiming(data: string, lang: any) {
    let now = Date.now();
    let given = Date.parse(data);

    let elapsed = now - given;

    if (elapsed < 60 * 1000) {
        return lang("timing.now");
    }
    else if (elapsed < 60 * 60 * 1000) {
        let minutes = Math.floor(timeToMinutes(elapsed));
        return lang("timing.minutes", { minutes, end: minutes > 1 ? "s" : "" });
    }
    else if (elapsed < 24 * 60 * 60 * 1000) {
        let hours = Math.floor(timeToHours(elapsed));
        return lang("timing.hours", { hours, end: hours > 1 ? "s" : "" });
    }
    return lang("timing.at", {date: new Date(given).toLocaleDateString()});
}

export function appendScopeKey(entries: IVaultKey[], key: IVaultKey) {
    let cp = [...entries];
    let index = entries.findIndex((k) => k._id === key._id);
    if (index >= 0) {
        cp[index] = key;
    }
    else {
        cp.push(key);
    }
    return cp;
}

export function removeScopeKey(entries: IVaultKey[], key: IVaultKey) {
    let index = entries.findIndex((k) => k._id === key._id);
    if (index >= 0) {
        let cp = [...entries];
        cp.splice(index, 1);
        return cp;
    }
    return [...entries];
}

export function padZero(value: number) {
    if (value >= 0 && value < 10) {
        return "0" + value;
    }
    return '' + value;
}

export function capitalize(str: string) {
    if (str.length === 0) {
        return str;
    }

    let cap = str[0].toUpperCase();
    if (str.length > 1) {
        cap += str.substring(1).toLowerCase();
    }
    return cap;
}

export enum FileSize {
    KB = "KB", MB = "MB", GB = "GB", TB = "TB"
}

export function sizeConvert(size: number, format: FileSize) {

    const kb = (s: number) => {
        return (s / 1024).toFixed(2);
    }

    const mb = (s: number) => {
        return  (s / Math.pow(1024, 2)).toFixed(2);
    }

    const gb = (s: number) => {
        return  (s / Math.pow(1024, 3)).toFixed(2);
    }

    const tb = (s: number) => {
        return  (s / Math.pow(1024, 4)).toFixed(2);
    }

    switch(format) {
        case FileSize.KB: {
            return kb(size);
        }
        case FileSize.MB: {
            return mb(size);
        }
        case FileSize.GB: {
            return gb(size);
        }
        case FileSize.TB: {
            return tb(size);
        }
        default : {
            return size
        }
    }
}

export function bestSizeRep(size: number) {
    if(size < Math.pow(1024,2)) {
        return sizeConvert(size, FileSize.KB) +" KB";
    }
    else if(size < Math.pow(1024, 3)) {
        return sizeConvert(size, FileSize.MB) +" MB";
    }
    else if(size < Math.pow(1024, 4)) {
        return sizeConvert(size, FileSize.GB) +" GB";
    }
    return sizeConvert(size, FileSize.TB)+" TB";
}

export function downloadFile(url: string, name: string) {
    let a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.click();
    a.remove();
}