import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Encryption } from "../core/Encryption";

export interface AesEncryptionScheme {
    key: string;
    iv: string;
}

export function ObjectEncryptionScheme(props: { scheme?: AesEncryptionScheme, onChange(scheme: AesEncryptionScheme): any }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.scheme) {
            let newKey = window.crypto.getRandomValues(new Uint8Array(32));
            let newIv = window.crypto.getRandomValues(new Uint8Array(12));

            let encryption = new Encryption();
            props.onChange({
                key: encryption.arrayToBase64(newKey),
                iv: encryption.arrayToBase64(newIv)
            })
        }
    }, [props]);

    return <Box>
        <Accordion disableGutters>
            <AccordionSummary>
                <Typography variant="h6" color="var(--palette-text-primary)">{t("security")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.scheme?.key && <Typography variant="body2" color="var(--palette-text-secondary)">
                    <span dangerouslySetInnerHTML={{ __html: t("securityKey", { key: props.scheme.key }) }}></span>
                </Typography>}
                {props.scheme?.iv && <Typography variant="body2" color="var(--palette-text-secondary)">
                    <span dangerouslySetInnerHTML={{ __html: t("securityIv", { iv: props.scheme.iv }) }}></span>
                </Typography>}
            </AccordionDetails>
        </Accordion>
    </Box>
}