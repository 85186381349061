import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Routes } from "../state/routes";
import Footer from "./Footer";
import Header from "./Header";
import "./Landing.scss";
import LinkCollaborators from "./LinkCollaborators";
import SectionAbout from "./SectionAbout";
import StartJourney from "./StartJourney";
import StayOrganized from "./StayOrganized";

export default function Landing() {
    const {isAuthenticated} = useAuth0();
    const his = useHistory();

    useEffect(() => {
        if(isAuthenticated) {
            his.replace(Routes.base)
        }
    }, [his, isAuthenticated]);

    return <Box className="page">
        <Header />
        <SectionAbout />
        <StayOrganized />
        <LinkCollaborators />
        <StartJourney/>
        <Footer/>
    </Box>
}