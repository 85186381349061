import { Box, Container, Typography } from "@material-ui/core";
import { Fade, Slide } from "react-awesome-reveal";
import BoxesPng from "../assets/boxes.png";

function StayOrganized() {
    return <Box component="section" className="section-stay-organized">
        <Container className="wrapper">
            <Box className="left">
                <Box marginY={4}>
                    <Slide triggerOnce>
                        <Typography variant="h4">
                            Gardez vos objets organisés avec les coffres.
                        </Typography>
                    </Slide>
                </Box>
                <Box marginY={2}>
                    <Fade triggerOnce>
                        <Typography variant="body1">
                            Séparez vos objets par projet ou par équipe afin de garder un oeil sur les objets en rapport avec un sujet.
                        </Typography>
                    </Fade>
                </Box>
            </Box>
            <Box className="right">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ isolation: "isolate" }} viewBox="4.14 1699.919 512 443.461" width="512pt" height="443.461pt" className="hexagon">
                    <polygon points="388.188,2143.324,132.188,2143.38,4.14,1921.705,132.092,1699.975,388.092,1699.919,516.14,1921.594" fill="rgb(255,152,0)" />
                </svg>
                <img src={BoxesPng} alt="box objects" className="img-boxes" />
            </Box>
        </Container>
    </Box >
}

export default StayOrganized;