import { IVaultKey } from ".";
import { AesEncryptionScheme } from "../ui/ObjectEncryption";

const SERVER_IV_LENGTH = 12

export class Encryption {

    base64ToArray(base64str: string) {
        let binary = window.atob(base64str);
        let uint8 = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            uint8[i] = binary.charCodeAt(i);
        }
        return uint8
    }

    arrayToBase64(arr: Uint8Array) {
        let str = '';
        for (let i = 0; i < arr.length; i++) {
            str += String.fromCharCode(arr[i])
        }
        return window.btoa(str);
    }

    bufferToBase64(buffer: ArrayBufferLike) {
        let intArray = new Uint8Array(buffer);
        return this.arrayToBase64(intArray);
    }

    async decryptObject(obj: IVaultKey) {
        if (obj.encrypted && obj.encryption) {
            let encryption = JSON.parse(obj.encryption);
            let keyBuffer = this.base64ToArray(encryption.key)
            let ivDataAuthBuffer = this.base64ToArray(obj.data);

            let ivBuffer = ivDataAuthBuffer.subarray(0, SERVER_IV_LENGTH);
            let dataBuffer = ivDataAuthBuffer.subarray(SERVER_IV_LENGTH);

            let key = await window.crypto.subtle.importKey("raw", keyBuffer, "AES-GCM", true, ["encrypt", "decrypt"]);
            let dec = await window.crypto.subtle.decrypt(
                {
                    name: 'AES-GCM',
                    iv: ivBuffer
                }, key, dataBuffer
            ).catch(console.error);

            return new TextDecoder().decode(dec);
        }
        return undefined;
    }

    async encryptUint8Array(obj: Uint8Array, scheme: AesEncryptionScheme) {
        let iv = this.base64ToArray(scheme.iv);
        let key = this.base64ToArray(scheme.key);

        let cipher = await window.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"]);
        let encrypted = new Uint8Array(await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                length: 256,
                iv
            }, cipher, obj
        ))

        let ivPlusData = new Uint8Array(iv.length+encrypted.length);
        ivPlusData.set(iv);
        ivPlusData.set(encrypted, iv.length);

        return ivPlusData
    }

    async decryptUint8Array(obj: Uint8Array, base64Key: string) {
        let key = this.base64ToArray(base64Key);
        let iv = obj.subarray(0,SERVER_IV_LENGTH);
        let content = obj.subarray(SERVER_IV_LENGTH);
        let cipher = await window.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"]);
        let decrypted = new Uint8Array(await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                length: 256,
                iv
            }, cipher, content
        ))
        return decrypted;
    }

    async encryptObject(obj: IVaultKey, scheme: AesEncryptionScheme, saveEncryption: boolean = true) {
        let iv = this.base64ToArray(scheme.iv);
        let key = this.base64ToArray(scheme.key);

        let cipher = await window.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"]);
        let encrypted = new Uint8Array(await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                length: 256,
                iv
            }, cipher, new TextEncoder().encode(obj.data)
        ))

        let ivPlusData = new Uint8Array(iv.length+encrypted.length);
        ivPlusData.set(iv);
        ivPlusData.set(encrypted, iv.length);

        obj.data = this.arrayToBase64(ivPlusData);
        
        let signature = await window.crypto.subtle.digest(
            "SHA-256",
            new TextEncoder()
                .encode(
                    JSON.stringify({ key: scheme.key })
                )
        )

        obj.encrypted = true
        obj.signature = this.bufferToBase64(signature);

        if (saveEncryption) {
            obj.encryption = JSON.stringify({key: scheme.key});
        }

        return obj;
    }
}