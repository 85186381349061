import { useEffect, useState } from "react";
import { IVaultKey } from "../core";
import { Encryption } from "../core/Encryption";
import { appState } from "./AppState"

export function useSharedBoxes() {
    return appState.vaultState.list.filter((v) => {
        return (v.collaborators || []).length > 0
    }).sort((a1, a2) => a1.label.localeCompare(a2.label));
}

export function usePrivateBoxes() {
    return appState.vaultState.list.filter((v) => {
        return (v.collaborators || []).length === 0
    }).sort((a1, a2) => a1.label.localeCompare(a2.label));
}

export function useObjectsByModel(model: string) {
    return appState.vaultState.objects.filter((obj) => obj.format === model).sort((a1, a2) => a1.code.localeCompare(a2.code));
}

export function useSearch() {
    return appState.search
}

export function useBoxObjects(box: string) {
    return appState.vaultState.objects.filter((obj) => obj.vault === box).sort((a1, a2) => a1.code.localeCompare(a2.code));
}

export function useReceivedInvites() {
    return appState.vaultState.pendingInvitations.filter((i) => i.guest.id === appState.profileState.profile?._id);
}

export function useDecryptedObject(obj: IVaultKey | undefined) {
    const [decrypted, setDecrypted] = useState<any>(undefined);

    useEffect(() => {
        if (obj) {
            new Encryption()
                .decryptObject(obj)
                .then((dec) => {
                    if (dec) {
                        setDecrypted(JSON.parse(dec));
                    }
                })
        }
    }, [obj]);

    return decrypted;
}