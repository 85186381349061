import { makeAutoObservable } from "mobx";
import { IUserVault, IVaultInvitation, IVaultKey } from "../core";
import { VaultClient } from "../core/VaultClient";

class VaultState {
    list: IUserVault[];
    objects: IVaultKey[];
    pendingInvitations: IVaultInvitation[];

    constructor() {
        makeAutoObservable(this);
        this.list = [];
        this.objects = [];
        this.pendingInvitations = [];
    }

    clone() {
        let next = new VaultState();
        next.list = [...this.list];
        next.pendingInvitations = [...this.pendingInvitations];
        return next;
    }

    find(vaultId: string) {
        return this.list.find((v) => v._id == vaultId || v.codeName == vaultId);
    }

    findinvite(ivId: string) {
        return this.pendingInvitations.find((v) => v._id == ivId);
    }

    setVaults(items: IUserVault[]) {
        this.list = items;
    }

    setInvitations(items: IVaultInvitation[]) {
        this.pendingInvitations = items;
    }

    setObjects(items: IVaultKey[]) {
        this.objects = items;
    }

    async fetchVaults() {
        let client = new VaultClient();
        let newObjects: IVaultKey[] = [];
        client.list()
            .then(list => {
                if (list) {
                    this.list = list;
                    Promise.all(
                        list.map(async (box) => {
                            return client.fetchKeys(box._id)
                        })
                    )
                        .then((complex) => {
                            this.appendObjects(complex.flat(1) as IVaultKey[]);
                        })
                }
            })
            .catch((err) => { });
    }

    async deleteObject(id: string) {
        let dObject = this.objects.find((o) => o._id === id);
        if (dObject) {
            return new VaultClient()
                .deleteKey(dObject.vault, dObject._id)
                .then((done) => {
                    if (done) {
                        this.objects = this.objects.filter((o) => o._id !== dObject?._id);
                        return true;
                    }
                    else {
                        return false;
                    }
                })
        }
    }

    async fetchInvitations() {
        return new VaultClient().fetchPendingInvites()
            .then(list => {
                if (list) {
                    this.pendingInvitations = list;
                }
            })
            .catch((err) => { });
    }

    removeVault(vaultId: string) {
        if (this.find(vaultId)) {
            this.list = (this.list.filter((v) => v._id != vaultId));
        }
    }

    appendVault(vault: IUserVault) {
        if (this.find(vault._id)) {
            this.list = [
                vault,
                ...(this.list.filter((v) => v._id != vault._id))
            ];
        }
        else {
            this.list.push(vault);
            new VaultClient()
                .fetchKeys(vault._id)
                .then((keys) => {
                    if (keys) {
                        this.appendObjects(keys);
                    }
                })
        }
    }

    appendObjects(objects: IVaultKey[]) {
        let copy = [...this.objects];
        objects.forEach((obj) => {
            let index = copy.findIndex((o) => o._id === obj._id);
            if (index > 0) {
                copy[index] = obj;
            }
            else {
                copy.push(obj);
            }
        })
        this.objects = copy;
    }

    appendObject(vaultdata: IVaultKey) {
        let index = this.objects.findIndex((o) => o._id === vaultdata._id);
        if (index > 0) {
            let copy = [...this.objects];
            copy[index] = vaultdata;
            this.objects = copy;
        }
        else {
            this.objects.push(vaultdata);
        }
    }

    removeObject(objectId: string) {
        let index = this.objects.findIndex((o) => o._id === objectId);
        if (index > 0) {
            let copy = [...this.objects];
            copy.splice(index, 1);
            this.objects = copy;
        }
    }

    appendPendingInvite(iv: IVaultInvitation) {
        if (!this.findinvite(iv._id)) {
            this.pendingInvitations.push(iv);
        }
    }

    removePendingInvite(ivId: string) {
        if (this.findinvite(ivId)) {
            this.pendingInvitations = this.pendingInvitations.filter((inv) => inv._id != ivId);
        }
    }
}

export default VaultState;