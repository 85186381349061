import { IconButton, Toolbar } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useState } from "react";
import { useEffect } from "react";
import { PropsWithChildren } from "react";
import { ArrowLeft } from "phosphor-react";
import { Box } from "@material-ui/system";

export function Header(props: PropsWithChildren<{ showReturn?: boolean }>) {
    const [showReturn, setShowReturn] = useState(false);

    useEffect(() => {
        if (window.history.length > 0 && props.showReturn) {
            setShowReturn(true);
        }

    }, [props.showReturn]);

    return <Box paddingY={1} display="flex" flexDirection="row">
        {
            showReturn && 
            <IconButton style={{marginRight: '4px'}} onClick={() => window.history.back()}>
                <ArrowLeft size={24} fill={grey[500]} />
            </IconButton>
        }
        {props.children}
    </Box>
}