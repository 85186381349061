import { Box, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SettingProfileCard from "./SettingProfileCard";
import SettingsAuthenticationAndSecurity from "./SettingsAuthenticationAndSecurity";

function SettingsPage() {
    const {t} = useTranslation();
    return <Box>
        <Box paddingY={2}>
            <Typography variant="h5" color="var(--palette-text-primary)">{t("headers.settings")}</Typography>
        </Box>
        <Box marginBottom={2}>
            <Paper>
                <SettingProfileCard />
            </Paper>
        </Box>
        <Box>
            <Paper>
                <SettingsAuthenticationAndSecurity />
            </Paper>
        </Box>
    </Box>
}

export default SettingsPage;