import React, { useContext } from "react";
import { appState, AppState } from "./AppState";

export const StoreContext = React.createContext<AppState>(appState);

export function useStoreState(){
    const state = useContext(StoreContext);
    if(state){
        return state;
    }
    throw new Error("useStoreState must be used within a StoreProvider");
}