import { Box, Container, Typography } from "@material-ui/core";
import { Fade, Slide } from "react-awesome-reveal";
import BoxCollaborators from "../assets/collaborators.png";

function LinkCollaborators() {
    return <Box component="section" className="section-link-collaborators">
        <Box className="left">
            <svg xmlns="http://www.w3.org/2000/svg" style={{ isolation: "isolate" }} viewBox="-63 3011.157 724.816 653.686" width="550px" height="auto">
                <g>
                    <ellipse vector-effect="non-scaling-stroke" cx="253.49999999999977" cy="3337.9999999999995" rx="316.5" ry="326.8431372549021" fill="rgb(33,150,243)" fill-opacity="0.2" />
                    <ellipse vector-effect="non-scaling-stroke" cx="388.4082278481" cy="3362.4999999999986" rx="273.4082278481003" ry="282.3431372549012" fill="rgb(33,150,243)" />
                </g>
            </svg>
            <img src={BoxCollaborators} alt="box collaborators" className="img-collaborators" />
        </Box>
        <Box className="right">
            <Container>
                <Box marginY={4}>
                    <Slide triggerOnce direction="right">
                        <Typography variant="h4">
                            Associez vos collaborateurs à vos coffres.
                        </Typography>
                    </Slide>
                </Box>
                <Box marginY={2}>
                    <Fade>
                        <Typography variant="body1">
                            Vous devez rendre des identifiants disponibles au sein de votre equipe? Invitez vos collaborateurs à vous rejoindre,
                            Ils accèdent aux objets au meme titre que vous et en temps réel.
                        </Typography>
                    </Fade>
                </Box>
            </Container>
        </Box>
    </Box>
}

export default LinkCollaborators;